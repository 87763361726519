/* ------------------------------------------------
  form
---------------------------------------------- */
.reserve {
  position: relative;
  border: 3px solid #f2f2f3;
  margin-top: 110px;
  padding: 100px 100px;
  @include mq(sp) {
    margin-top: 45px;
    padding: 40px 20px 5px;
  }
  &:before {
    position: absolute;
    top: -31px;
    left: 50%;
    width: 1px;
    height: 63px;
    background: #000;
    content: "";
    @include mq(sp) {
      height: 46px;
      top: -23px;
    }
  }
  &__ttl {
    margin-bottom: 50px;
    text-align: center;
    font-size: 22px;
    @include mq(sp) {
      margin-bottom: 30px;
      font-size: 18px;
    }
    .font-en {
      display: block;
      font-size: 10px;
    }
  }
  &__note {
    margin-bottom: 65px;
    text-align: center;
    text-decoration: underline;
    @include mq(sp) {
      margin-bottom: 10px;
    }
  }
}
.form {
  .must {
    color: #ed1c24;
  }
  &__table {
    margin-bottom: 40px;
    @include mq(sp) {
      display: block;
      margin-bottom: 20px;
      width: 100%;
    }
    tbody {
      @include mq(sp) {
        display: block;
        width: 100%;
      }
    }
    tr {
      @include mq(sp) {
        display: block;
        width: 100%;
      }
    }
    th {
      width: 216px;
      vertical-align: top;
      padding: 35px 0;
      @include mq(sp) {
        display: block;
        width: 100%;
        padding: 0 0;
        font-size: 15px;
        margin-bottom: 5px;
      }
    }
    td {
      vertical-align: top;
      padding: 35px 0;
      @include mq(sp) {
        display: block;
        padding: 0;
        margin-bottom: 15px;
      }
    }
    .line {
      th {
        border-bottom: 1px solid #f2f2f3;
        @include mq(sp) {
          border-bottom: none;
        }
      }
      td {
        border-bottom: 1px solid #f2f2f3;
        @include mq(sp) {
          padding-bottom: 35px;
        }
      }
    }
  }
  &__check-list {
    text-align: left;
    width: 420px;
    @include mq(sp) {
      width: auto;
      max-width:  240px;
    }
    label {
      display: inline-block;
      @include mq(sp) {
        margin-left: 0;
      }
      a {
        color: $color;
        text-decoration: underline;
        &:hover {
          text-decoration: none;
        }
      }
      &:before {
        top: 8px;
        left: 0;
        width: 20px;
        height: 20px;
        box-sizing: border-box;
        border: none;
        background: #f6f6f6;
        border-radius: 2px;
      }
      &.checked {
        &:after {
          top: 11px;
          left: 4px;
          width: 14px;
          height: 6px;
          border-left: 1px solid #000;
          border-bottom: 1px solid #000;
          transform: rotate(-45deg);
        }
      }
    }
    li {
      display: inline-block;
    }
    .sp-block {
      @include mq(sp) {
        display: block;
      }
    }
    .block {
      margin-bottom: 10px;
      display: block;
    }
  }
  &__list {
    @include clearfix;
    @include mq(sp) {
      margin-top: 30px;
    }
    dt {
      float: left;
      margin-bottom: 20px;
      @include mq(sp) {
        float: none;
        margin-bottom: 5px;
        font-size: 15px;
      }
    }
    dd {
      margin-left: 156px;
      margin-bottom: 20px;
      @include mq(sp) {
        margin-left: 0;
        font-size: 15px;

      }
    }
  }
  &__select {
    display: inline-block;
    border-radius: 4px;
    background-color: #f6f6f6;
    background-image: url("../img/common/ico_select_01.png");
    background-repeat: no-repeat;
    background-position: right center;
    background-size: 20px auto;
    border: none;
    width: 120px;
    height: 45px;
    box-sizing: border-box;
    padding: 0 15px;
    line-height: 45px;
    margin-right: 10px;
    font-size: 15px;
    cursor: pointer;
    -webkit-appearance: none;
    @include mq(sp) {
      font-size: 16px;
    }
  }
  label {
    position: relative;
    cursor: pointer;
    padding-left: 30px;
    font-size: 16px;
    z-index: 2;
    background: #fff;
    margin-right: 20px;
    margin-bottom: 10px !important;
    &:before {
      position: absolute;
      content: "";
      z-index: 2;
    }
    &.checked {
      &:after {
        position: absolute;
        content: "";
        z-index: 3;
      }
    }
  }
  /*
  &__label {
    display: inline-block;
    position: relative;
    cursor: pointer;
    padding-left: 30px;
    font-size: 16px;
    z-index: 2;
    background: #fff;
    margin-right: 20px;
    margin-bottom: 10px;
    @include mq(sp) {
      line-height: 2.25;
      font-size: 15px;
    }
    &:before {
      position: absolute;
      content: "";
      z-index: 2;
    }
    &.checked {
      &:after {
        position: absolute;
        content: "";
        z-index: 3;
      }
    }
    &--checkbox {
      @include mq(sp) {
        margin-left: 0;
      }
      a {
        color: $color;
        text-decoration: underline;
        &:hover {
          text-decoration: none;
        }
      }
      &:before {
        top: 8px;
        left: 0;
        width: 20px;
        height: 20px;
        box-sizing: border-box;
        border: none;
        background: #f6f6f6;
        border-radius: 2px;
      }
      &.checked {
        &:after {
          top: 11px;
          left: 4px;
          width: 14px;
          height: 6px;
          border-left: 1px solid #000;
          border-bottom: 1px solid #000;
          transform: rotate(-45deg);
        }
      }
    }
  }
  */
  &__checkbox {
    position: absolute;
    top: 0;
    left: 10px;
    z-index: -1;
    opacity: 0;
  }
  &__item {
    display: inline-block;
    margin-right: 20px;
    @include mq(sp) {
      margin-right: 2px;
      .form__select {
        margin-right: 5px;
        padding: 0 0 0 8px;
        width: 54px;
      }
    }
  }
  &__input {
    display: block;
    width: 100%;
    font-size: 15px;
    border: none;
    box-shadow: none;
    padding: 13px 15px;
    letter-spacing: 0.06em;
    border-radius: 4px;
    background: #f6f6f6;
    -webkit-appearance: none;
    box-sizing: border-box;
    @include input-placeholder {
      color: #ddd;
    }
    @include mq(sp) {
      font-size: 16px;
    }
  }
  &__textarea {
    display: block;
    width: 100%;
    height: 180px;
    border: none;
    -webkit-appearance: none;
    box-shadow: none;
    border-radius: 0;
    resize: none;
    background: #f6f6f6;
    font-size: 16px;
    padding: 12px 15px;
    box-sizing: border-box;
     border-radius: 4px;
    @include mq(sp) {
      margin-bottom: 25px;
    }
  }
  &__btn-list {
    text-align: center;
    @include mq(sp) {
      display: flex;
      flex-direction: column-reverse;
    }
    li {
      display: inline-block;
      margin: 0 20px;
      @include mq(sp) {
        display: block;
        margin: 0 0 20px;
      }
    }
  }
  &__reset-btn {
    position: relative;
    width: 280px;
    height: 64px;
    line-height: 64px;
    font-size: 16px;
    border: none;
    background: #b3b3b3;
    color: #fff;
    border-radius: 0;
    box-shadow: none;
    cursor: pointer;
    @include mq(sp) {
      width: 100%;
    }
    &:before {
      position: absolute;
      top: 50%;
      right: 20px;
      width: 40px;
      height: 1px;
      background: #fff;
      content: "";
      transition: all 300ms;
    }
    &:hover {
      &:before {
        width: 60px;
        right: 0;
      }
    }
  }
  &__back-btn {
    position: relative;
    width: 280px;
    height: 64px;
    line-height: 64px;
    font-size: 16px;
    border: none;
    background: #b3b3b3;
    color: #fff;
    border-radius: 0;
    box-shadow: none;
    cursor: pointer;
    @include mq(sp) {
      width: 100%;
    }
    &:before {
      position: absolute;
      top: 50%;
      left: 20px;
      width: 40px;
      height: 1px;
      background: #fff;
      content: "";
      transition: all 300ms;
    }
    &:hover {
      &:before {
        width: 60px;
        left: 0;
      }
    }
  }
  &__submit-btn-wrap {
    position: relative;
    &:before {
      position: absolute;
      top: 50%;
      right: 20px;
      width: 40px;
      height: 1px;
      background: #fff;
      content: "";
      transition: all 300ms;
    }
    &:hover {
      &:before {
        width: 60px;
        right: 0;
      }
    }
  }
  &__submit-btn {
    width: 280px;
    height: 64px;
    line-height: 64px;
    font-size: 16px;
    border: none;
    background: #000;
    color: #fff;
    border-radius: 0;
    box-shadow: none;
    cursor: pointer;
    @include mq(sp) {
      width: 100%;
    }
  }
}
.form__check-list .block_item {
  .block_item_after {
    display: none;
  }
  &.show {
    .block_item_after {
      display: inline;
    }
  }
}
//add
.mw_wp_form .horizontal-item + .horizontal-item {
    margin-left: 0 !important;
}
.mw_wp_form_confirm {
  .form__list dt,
  .form__reset-btn {
    display: none !important;
   }
  .form__list dd {
    margin-left: 0;
    margin-bottom: 0;
  }
}
.mw_wp_form .error {
 font-size: 12px !important; 
}