/* ------------------------------------------------
 	common     
  ---------------------------------------------- */
body {
  font-family: a-otf-gothic-bbb-pr6n, sans-serif;
  font-weight: 400;
  font-style: normal;
  -webkit-text-size-adjust: 100%;
  color: $color;
  background: $bg;
  font-size: 15px;
  line-height: 2;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  letter-spacing: 0.1em;
  @include mq(sp) {
    font-size: 14px;
  }
}
a {
  color: $aColor;
  @include txtLine(hLine);
  @include mq(sp) {
    color: black;
  }
}
strong {
  font-weight: bold;
}
.font-en {
  font-family: 'Quicksand', sans-serif;
}
.font-midashi {
  font-family: a-otf-midashi-go-mb31-pr6n, sans-serif;
  font-weight: 600;
  font-style: normal;
}
.container {
  width: 1080px;
  margin: 0 auto;
  box-sizing: border-box;
  @include mq(sp) {
    width: 100%;
    padding: 0 25px;
  }
}
.bg_img {
  visibility: hidden;
}
/* wrapper
-------------------------*/
.wrapper {
  min-width: 1200px;
  margin: 0 auto;
  overflow: hidden;
  @include mq(sp) {
    min-width: inherit;
    padding-top: 60px;
    padding-bottom: 65px;
  }
  img {
    @include rwd-img;
    @include mq(sp) {
      max-width: inherit;
      width: 100%;
    }
  }
}
/* header
-------------------------*/
.header {
  position: relative;
  box-sizing: border-box;
  padding-top: 40px;
  height: 205px;
  @include mq(sp) {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 60px;
    box-sizing: border-box;
    z-index: 98;
    padding-top: 0 !important;
    background: #fff;
  }
  &__logo {
    position: absolute;
    left: 50px;
    width: 132px;
    @include mq(sp) {
      top: 10px !important;
      left: 12px !important;
      width: 142px !important;
      height: 42px !important;
      background-image: url("../img/common/logo_sp.png");
      background-repeat: no-repeat;
      background-position: left top;
      background-size: 100% auto;
      z-index: 98;
      img {
        display: none;
      }
    }
  }
  &__menu-btn {
    position: absolute;
    top: 20px;
    right: 25px;
    width: 40px;
    height: 15px;
    height: 25px;
    box-sizing: border-box;
    &:before {
      position: absolute;
      top: 5px;
      left: 0;
      box-sizing: border-box;
      width: 40px;
      height: 15px;
      border-top: 1px solid #000;
      border-bottom: 1px solid #000;
      content: "";
      transition: all 250ms ease-out;
    }
    &:after {
      position: absolute;
      top: 12px;
      left: 0;
      content: "";
      width: 40px;
      height: 1px;
      background: #000;
      transition: all 250ms ease-out;
    }
    &.is_open {
      &:before {
        transform: rotate(45deg);
        border-bottom: none;
        top: 10px;
        left: -5px;
      }
      &:after {
        transform: rotate(-45deg);
        top: 12px;
        left: 1px;
      }
    }
  }
  &--top {
    height: 100px;
    @include mq(sp) {
      height: 60px;
    }
    .gnav {
      position: relative;
      top: -5px;
      @include mq(sp) {
        top: 60px;
      }
    }
    .header__logo {
      top: 20px;
      left: 33px;
      width: 39px;  
    }
  }
}
/* gnav
-------------------------*/
.gnav {
  text-align: center;
  @include mq(sp) {
    display: none;
    position: relative;
    background: #fff;
    padding-top: 20px;
    box-sizing: border-box;
    height: 100vh;
    top: 60px;
    padding-bottom: 25px;
    animation:  showAnime 250ms ease-out;
    &.is_open {
      display: block;
    }
  }
  &__ttl {
    display: none;
    @extend .font-en;
    @include mq(sp) {
      margin-bottom: 20px;
      padding-bottom: 5px;
      display: inline-block !important;
      font-size: 10px;
      background-image: url("../img/common/line_02.png");
      background-repeat: no-repeat;
      background-position: center bottom;
      background-size: auto 3px;
    }
  }
  &__list {
    display: flex;
    justify-content: center;
    @include mq(sp) {
      display: block;
    }
  }
  &__item {
    position: relative;
    @include mq(sp) {
      margin-bottom: 20px;
    }
    &:before {
      position: absolute;
      top: 14px;
      left: 0;
      width: 8px;
      height: 15px;
      background-image: url("../img/common/ico_nav_01.png");
      background-size: 100% auto;
      content: "";
      @include mq(sp) {
        display: none;
      }
    }
    &:last-child {
      &:after {
        position: absolute;
        top: 14px;
        right: 0;
        width: 8px;
        height: 15px;
        background-image: url("../img/common/ico_nav_01.png");
        background-size: 100% auto;
        content: "";
        @include mq(sp) {
          display: none;
        }
      }
    }
  }
  &__en {
    display: block;
    font-size: 14px;
    line-height: 1.4;
    @extend .font-en;
  }
  &__txt {
    display: block;
    font-size: 12px;
    line-height: 1.75;
  }
  &__link {
    position: relative;
    top: 0;
    display: block;
    padding: 0 35px;
    transition: top 250ms ease-out;
    &:hover {
      top: 5px;
      text-decoration: none;
      @include mq(sp) {
        top: 0;
      }
    }
  }
  &__sns {
    display: none;
    @extend .font-en;
    @include mq(sp) {
      display: block;
      font-size: 12px;
      text-align: center;
    }
    &-item {
      display: inline-block;
      margin: 0 15px;
    }
  }
}
.sns-btn {
  position: fixed;
  top: 50%;
  right: 20px;
  width: 36px;
  height: 118px;
  background-image: url("../img/common/bg_sns_01.png");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 32px auto;
  z-index: 90;
  &__item {
    position: absolute;
    left: -1px;
    width: 37px;
    &--fb {
      top: 2px;
    }
    &--in {
      bottom: 2px;
    }
  }
}
.reserve-btn {
  display: block;
  position: fixed;
  top: -10px;
  right: 20px;
  width: 115px;
  height: 156px;
  background-image: url("../img/common/btn_reserve_01.png");
  background-repeat: no-repeat;
  background-position: left bottom;
  background-size: 115px auto;
  z-index: 80;
  transition: top 250ms ease-out;
  &:hover {
    top: 0;
  }
  &__txt {
    position: absolute;
    text-align: center;
    bottom: 33px;
    left: 0;
    width: 100%;
    font-size: 12px;
    .font-en {
      display: block;
      font-size: 14px;
      line-height: 1;
    }
  }
}
/* breadcrumb
-------------------------*/
.breadcrumb {
    
  &__item {
    
    &--current {
      cursor: default;
      color: $color;
    }
  }
}

/* contents
-------------------------*/
.contents {
  padding-bottom: 85px;
  @include mq(sp) {
    padding-bottom: 40px;
  }
}

/* footer
-------------------------*/
.footer {
  position: relative;
  
  .container {
    position: relative;
    padding-top: 110px;
    @include clearfix;
    @include mq(sp) {
      padding-top: 40px;
    }
    &:before {
      position: absolute;
      top: 0;
      left: -160px;
      width: 1400px;
      height: 5px;
      position: absolute;
      background-image: url("../img/common/line_02.png");
      background-repeat: no-repeat;
      background-position: left top;
      background-size: auto 5px;
      content: "";
    }
  }
  &__address {
    float: left;
    width: 50%;
    @include mq(sp) {
      float: none;
      width: 100%;
    }
  }
  &__fb-wrap {
    position: relative;
    margin-bottom: 115px;
    margin-left: 50%;
    @include mq(sp) {
      max-width: 500px;
      margin: 0 auto 40px;
    }
    &:before {
      right: -20px;
      bottom: -20px;
      @include bg-dot;
      border-radius: 10px;
      @include mq(sp) {
        right: -10px;
        bottom: -10px;
        border-radius: 8px;
      }
    }
  }
  &__facebook {
    position: relative;
    width: 540px;
    height: 380px;
    padding: 20px;
    box-sizing: border-box;
    border: 1px solid #000;
    border-radius: 10px;
    background: #fff;
    z-index: 3;
    @include mq(sp) {
      max-width: 500px;
      margin: 0 auto;
      width: 100%;
      height: 340px;
      border-radius: 8px;
    }
    .fb-page {
      z-index: 4;
      @include mq(sp) {
        width: 100% !important;
        height: 300px !important;
      }
    }
    .fb_iframe_widget span,
    iframe {
      @include mq(sp) {
        width: 100% !important;
        height: 300px !important;
      }
    }
  }
  &__facebook-ttl {
    position: absolute;
    top: -14px;
    padding: 0 10px;
    right: 50px;
    display: inline-block;
    background: #fff;
    @extend .font-en;
    font-size: 14px;
    z-index: 5;
  }
  &__logo {
    width: 168px;
    float: left;
    @include mq(sp) {
      float: none;
      width: 110px;
      margin: 0 auto 10px;
    }
  }
  &__txt-wrap {
    margin-left: 205px;
    @include mq(sp) {
      margin-left: 0;
      text-align: center;
      margin-bottom: 30px;
    }
  }
  &__txt {
    padding-top: 10px;
    font-size: 14px;
    margin-bottom: 20px;
    @include mq(sp) {
      padding-top: 0;
      margin-bottom: 1em;
    }
  }
  &__phone {
    display: inline-block;
    margin-bottom: 20px;
    font-size: 22px;
    @extend .font-en;
    background-image: url("../img/common/line_02.png");
    background-size: auto 3px;
    background-position: left bottom;
    background-repeat: no-repeat;
    letter-spacing: 0.2em;
  }
  &__btn {
    position: relative;
    display: block;
    text-align: center;
    line-height: 50px;
    height: 50px;
    font-size: 15px;
    width: 270px;
    background: #feecd2;
    border: 1px solid #000;
    border-radius: 10px;
    @include mq(sp) {
      margin: 30px auto;
    }
    &:hover {
      text-decoration: none; 
      &:before {
        left: -13px;
      }
    }
    &:before {
      position: absolute;
      top: 50%;
      left : -23px;
      width: 45px;
      height: 1px;
      background: #000;
      content: "";
      transition: all 250ms ease-out;
    }
  }
  &__map {
    iframe {
      display: block;
      width: 100%;
      height: 480px;
      @include mq(sp) {
        height: 375px;
      }
    }
  }
}
.reserve-sp {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background: #feecd2;
  z-index: 98;
  height: 65px;
  padding: 0 25px;
  box-sizing: border-box;
  &__txt {
    font-size: 10px;
  }
  &__phone {
    font-size: 16px;
    padding-top: 14px;
    line-height: 1.4;
    @extend .font-en;
  }
  &__btn {
    position: absolute;
    right: 10px;
    top: 8px;
    text-align: center;
    width: 150px;
    height: 50px;
    background: #fff;
    line-height: 1.4;
    padding-top: 7px;
    border-radius: 10px;
    box-sizing: border-box;
    .font-en {
      display: block;
      font-size: 8px;
    }
    &:hover {
      text-decoration: none;
    }
    &:before {
      position: absolute;
      top: 50%;
      left: -12px;
      width: 25px;
      height: 1px;
      background: #000;
      content: "";
    }
  }
}
