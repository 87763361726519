/* ------------------------------------------------
 	common  parts    
  ---------------------------------------------- */
.wrap,.fade {
  display: block;
  text-decoration: none;

  &:hover {
    text-decoration:none;
  }
}
.ib {
  display: inline-block;
}
.phoneTxt {
  text-decoration: none !important;
  cursor: default;
}
.is_pc {
  @include mq(sp) {
    display: none;
  }
}
.is_sp {
  display: none !important;
  @include mq(sp) {
    display: block !important;
  }
}
.font-underline {
  text-decoration: underline;
}
.check-list {
  li {
    display: inline-block;
    margin-right: 20px;
    margin-bottom: 5px;
    padding-left: 24px;
    background-image: url("../img/common/ico_check_01.png");
    background-repeat: no-repeat;
    background-position: left 12px;
    background-size: 15px auto;
    font-size: 16px;
    @include mq(sp) {
      font-size: 14px;
      margin-right: 10px;
    }
  }
}
.data-list {
  dt {
    clear: both;
    float: left;
    width: 65px;
    margin-bottom: 5px;
    font-size: 16px;
    @include mq(sp) {
      font-size: 14px;
      float: none;
      width: auto;
      margin-bottom: 0;
    }
    span {
      display: inline-block;
      font-size: 14px;
      margin-bottom: 0;
      @include mq(sp) {
        font-size: 12px;
        margin-bottom: 0;
      }
    }
  }
  dd {
    position: relative;
    padding-left: 126px;
    margin-bottom: 5px;
    font-size: 16px;
    @include mq(sp) {
      font-size: 14px;
      padding-left: 0;
      margin-bottom: 10px;
    }
    &:before {
      position: absolute;
      top: 16px;
      left: 65px;
      width: 50px;
      height: 1px;
      background: #000;
      content: "";
      @include mq(sp) {
        display: none;
      }
    }
    span {
      display: inline-block;
      font-size: 14px;
      margin-bottom: 0;
      @include mq(sp) {
        font-size: 12px;
        margin-bottom: 0;
      }
    }
  }
  &--line {
    margin-top: 30px;
    padding-top: 30px;
    border-top: 1px solid #000;
    @include mq(sp) {
      margin-top: 18px;
      padding-top: 18px;
    }
  }
  &--rent {
    dt {
      width: 195px;
      @include mq(sp) {
        width: 100%;
      }
    }
    dd {
      padding-left: 260px;
      @include mq(sp) {
        padding-left: 0;
      }
      &:before {
        left: 195px;
        @include mq(sp) {
          display: none;
        }
      }
    }
  }
  &--price {
    dt {
      @include mq(sp) {
        width: 155px;
        float: left;
      }
    }
    dd {
      @include mq(sp) {
        padding-left: 155px;
      }
      &:before {
        left: 195px;
        @include mq(sp) {
          display: none;
        }
      }
    }
  }
  &--block {
    dt {
      margin-bottom: 22px;
      @include mq(sp) {
        margin-bottom: 0;
      }
    }
    dd {
      margin-bottom: 22px;
      @include mq(sp) {
        margin-bottom: 15px;
      }
    }
  }
}
.note-txt {
  margin-top: 10px;
  font-size: 14px;
  @include mq(sp) {
    margin-bottom: 10px;
    font-size: 12px;
  }
}
.note-list {
  text-align: right;
  li {
    display: inline-block;
    white-space: nowrap;
    margin-right: 10px;
    @include mq(sp) {
      font-size: 10px;
    }
  }
  &--mb {
    margin-bottom: 100px;
    @include mq(sp) {
      margin-bottom: 50px;
    }
  }
}