/* ------------------------------------------------
  blog
---------------------------------------------- */
.layout {
  padding-top: 80px;
  @include mq(sp) {
    padding-top: 0;
  }
  .container {
    @include clearfix;
  }
  &__main {
    width: 760px;
    float: left;
    @include mq(sp) {
      width: 100%;
      float: none;
      margin-bottom: 75px;
    }
  }
  &__side {
    width: 240px;
    float: right;
    @include mq(sp) {
      width: 100%;
      float: none;
    }
  }
}
.blog-list {
  border-top: 1px solid #000;
  padding-top: 50px;
  @include mq(sp) {
    padding-top: 0;
    border-top: none;
  }
  &__item {
    position: relative;
    margin-bottom: 50px;
    border-bottom: 1px solid #000;
    @include mq(sp) {
      margin-bottom: 25px;
      border-bottom: none;
    }
  }
  &__ttl {
    position: relative;
    font-size: 16px;
    margin-bottom: 55px;
    @include mq(sp) {
      text-align: center;
      font-size: 14px;
      padding-bottom: 30px;
      margin-bottom: 15px;
    }
    &:before {
      position: absolute;
      left: -35px;
      top: 50%;
      width: 20px;
      height: 1px;
      background: #000;
      content: "";
      @include mq(sp) {
        width: 1px;
        height: 20px;
        left: 50%;
        bottom: 0;
        top: inherit;
      }
    }
  }
  &__list {
    @include clearfix;
  }
  &__link {
    display: block;
    @include fadeAnime;
    @include clearfix;
    &:hover {
      text-decoration: none;
    }
  }
  &__img-wrap {
    position: relative;
    z-index: 2;
    margin-bottom: 50px;
    width: 300px;
    height: 200px;
    float: left;
    @include mq(sp) {
      width: 100px;
      height: 74px;
      margin-bottom: 0;
    }
    &:before {
      right: -10px;
      bottom: -10px;
      @include bg-dot-02;
      border-radius: 10px;
      content: "";
      @include mq(sp) {
        display: none;
      }
    }
  }
  &__img {
    position: relative;
    z-index: 3;
    width: 300px;
    height: 200px;
    overflow: hidden;
    border-radius: 10px;
    border: 1px solid #000;
    box-sizing: border-box;
    @include mq(sp) {
      width: 100px;
      height: 74px;
      border-radius: 3px;
    }
    img {
      max-width: inherit;
      height: 100%;
    }
  }
  &__item-ttl {
    position: relative;
    margin-left: 343px;
    font-size: 18px;
    padding-bottom: 20px;
    margin-bottom: 20px;
    &:before {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 40px;
      height: 3px;
      background-image: url("../img/common/line_02.png");
      background-repeat: no-repeat;
      background-position: left center;
      background-size: auto 3px;
      content: "";
      @include mq(sp) {
        display: none;
      }
    }
    @include mq(sp) {
      margin-bottom: 0;
      margin-left: 110px;
      padding-bottom: 0;
      font-size: 14px;
    }
  }
  &__txt {
    margin-bottom: 50px;
    font-size: 14px;
    margin-left: 343px;
    @include mq(sp) {
        display: none;
      }
  }
  &__meta {
    margin-left: 343px;
    @include mq(sp) {
      margin-left: 110px;
    }
  }
  &__date {
    font-size: 10px;
    @extend .font-en;
    @include mq(sp) {
      line-height: 1.4;
      vertical-align: middle;
    }
  }
  &__category {
    position: absolute;
    top: 20px;
    left: -7px;
    display: block;
    width: 110px;
    height: 28px;
    line-height: 28px;
    background: #fff;
    font-size: 10px;
    z-index: 4;
    text-align: center;
    border-radius: 0 6px 6px 0;
    border: 1px solid #000;
    border-left: none;
    box-sizing: border-box;
    @include mq(sp) {
      display: inline-block;
      position: relative;
      top: 0;
      left: 0;
      height: auto;
      line-height: 1.4;
      border: 1px solid #000;
      border-radius: 4px;
      width: 67px;
      vertical-align: middle;
      margin-left: 10px;
    }
  }
}
.blog {
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
  margin-bottom: 90px;
  padding-top: 50px;
  padding-bottom: 80px;
  @include mq(sp) {
    border-bottom: none;
    margin-bottom: 60px;
    padding-bottom: 0;
  }
  &__top {
    position: relative;
    text-align: center;
    margin-bottom: 40px;
    &:before {
      position: absolute;
      bottom: 0;
      left: 50%;
      width: 40px;
      margin-left: -20px;
      height: 3px;
      background-image: url("../img/common/line_02.png");
      background-repeat: no-repeat;
      background-position: left center;
      background-size: auto 3px;
      content: "";
    }
  }
  &__ttl {
    position: relative;
    display: inline;
    background: linear-gradient(transparent 70%,#feecd2 70%,#feecd2 100%,transparent 100%);
    font-size: 20px;
    text-align: center;
    @include mq(sp) {
      font-size: 18px;
    }
  }
  &__date {
    display: block;
    padding-top: 20px;
    padding-bottom: 20px;
    font-size: 12px;
    text-align: center;
    @include mq(sp) {
      padding-top: 10px;
    }
  }
  &__content {
    p {
      font-size: 14px;
      margin-bottom: 1.5em;
    }
  }
  &__category {
    font-size: 14px;
    @include mq(sp) {
      font-size: 12px;
    }
    a {
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
    }
  }
}
.side {
  position: relative;
  padding: 20px 25px 30px;
  margin-bottom: 30px;
  border-top: 1px solid #000;
  font-size: 14px;
  &:before {
    position: absolute;
    top: -15px;
    left: 50%;
    width: 1px;
    height: 15px;
    background: #000;
    content: "";
  }
  &__ttl-wrap {
    margin-bottom: 10px;
    text-align: center;
  }
  &__ttl {
    display: inline;
    font-size: 16px;
    padding: 0 10px;
    background: linear-gradient(transparent 60%,#feecd2 60%,#feecd2 100%,transparent 100%);
  }
  &__sub {
    font-size: 13px;
  }
  &__name {
    font-size: 18px;
    margin-bottom: 20px;
  }
  &__txt {
    font-size: 13px;
  }
}
.pager {
  position: relative;
  @include clearfix;
  text-align: center;
  @extend .font-en;
  &__item {
    display: inline-block;
    border-bottom: 1px solid #000;
    padding: 0 15px 5px;
    font-size: 16px;
    @include mq(sp) {
      padding: 0 10px 5px;
    }
    &--prev {
      position: absolute;
      top: 0;
      left: 0;
    }
    &--next {
      position: absolute;
      top: 0;
      right: 0;
    }
    a {
      &:hover {
        text-decoration: none;
      }
    }
  }
}
#wp-calendar {
  @extend .font-en;
  width: 100%;
  text-align: center;
  font-size: 12px;
  caption,th,td {
    text-align: center;
  }
  td {
    padding: 5px 0;
  }
}
.wp-pagenavi {
  margin-top: 60px;
  padding-top: 30px;
  text-align: center;
  font-size: 16px;
  @extend .font-en;
  @include mq(sp) {
    margin-top: 40px;
    font-size: 14px;
  }
  .page,
  .current {
    display: inline-block;
    width: 54px;
    border-bottom: 1px solid #000;
    margin: 0 10px;
    @include mq(sp) {
      width: 34px;
    }
  }
  .current {
    position: relative;
    &:before {
      position: absolute;
      top: -30px;
      left: 50%;
      width: 1px;
      height: 14px;
      background: #000;
      content: "";
      @include mq(sp) {
        height: 9px;
        top: -14px;
      }
    }
  }
  .previouspostslink {
    display: inline-block;
    width: 94px;
    margin-right: 30px;
    border-bottom: 1px solid #000;
    @include mq(sp) {
     width: 54px; 
    }
  }
  .nextpostslink {
    display: inline-block;
    width: 94px;
    margin-left: 30px;
    border-bottom: 1px solid #000;
    @include mq(sp) {
      width: 54px;
    }
  }
  a {
    text-decoration: none;
  }
}