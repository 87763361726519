html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  font-size: 100%;
  vertical-align: baseline;
}

html {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

caption, th, td {
  text-align: left;
  font-weight: normal;
  vertical-align: middle;
}

q, blockquote {
  quotes: none;
}

q:before, q:after, blockquote:before, blockquote:after {
  content: "";
  content: none;
}

a img {
  border: none;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section, summary {
  display: block;
}

/*clear fix*/
@keyframes showAnime {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* ------------------------------------------------
 	common     
  ---------------------------------------------- */
body {
  font-family: a-otf-gothic-bbb-pr6n, sans-serif;
  font-weight: 400;
  font-style: normal;
  -webkit-text-size-adjust: 100%;
  color: #000;
  background: #fff;
  font-size: 15px;
  line-height: 2;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  letter-spacing: 0.1em;
}

@media screen and (max-width: 768px) {
  body {
    font-size: 14px;
  }
}

a {
  color: #000;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

@media screen and (max-width: 768px) {
  a {
    color: black;
  }
}

strong {
  font-weight: bold;
}

.font-en, .gnav__ttl, .gnav__en, .gnav__sns, .footer__facebook-ttl, .footer__phone, .reserve-sp__phone, .top-blog__date, .top-blog__more, .page-section--menu .container:after, .blog-list__date, .pager, #wp-calendar, .wp-pagenavi {
  font-family: 'Quicksand', sans-serif;
}

.font-midashi {
  font-family: a-otf-midashi-go-mb31-pr6n, sans-serif;
  font-weight: 600;
  font-style: normal;
}

.container {
  width: 1080px;
  margin: 0 auto;
  box-sizing: border-box;
}

@media screen and (max-width: 768px) {
  .container {
    width: 100%;
    padding: 0 25px;
  }
}

.bg_img {
  visibility: hidden;
}

/* wrapper
-------------------------*/
.wrapper {
  min-width: 1200px;
  margin: 0 auto;
  overflow: hidden;
}

@media screen and (max-width: 768px) {
  .wrapper {
    min-width: inherit;
    padding-top: 60px;
    padding-bottom: 65px;
  }
}

.wrapper img {
  display: block;
  max-width: 100%;
  height: auto;
  width: auto;
  margin: 0 auto;
}

@media screen and (max-width: 768px) {
  .wrapper img {
    max-width: inherit;
    width: 100%;
  }
}

/* header
-------------------------*/
.header {
  position: relative;
  box-sizing: border-box;
  padding-top: 40px;
  height: 205px;
}

@media screen and (max-width: 768px) {
  .header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 60px;
    box-sizing: border-box;
    z-index: 98;
    padding-top: 0 !important;
    background: #fff;
  }
}

.header__logo {
  position: absolute;
  left: 50px;
  width: 132px;
}

@media screen and (max-width: 768px) {
  .header__logo {
    top: 10px !important;
    left: 12px !important;
    width: 142px !important;
    height: 42px !important;
    background-image: url("../img/common/logo_sp.png");
    background-repeat: no-repeat;
    background-position: left top;
    background-size: 100% auto;
    z-index: 98;
  }
  .header__logo img {
    display: none;
  }
}

.header__menu-btn {
  position: absolute;
  top: 20px;
  right: 25px;
  width: 40px;
  height: 15px;
  height: 25px;
  box-sizing: border-box;
}

.header__menu-btn:before {
  position: absolute;
  top: 5px;
  left: 0;
  box-sizing: border-box;
  width: 40px;
  height: 15px;
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
  content: "";
  transition: all 250ms ease-out;
}

.header__menu-btn:after {
  position: absolute;
  top: 12px;
  left: 0;
  content: "";
  width: 40px;
  height: 1px;
  background: #000;
  transition: all 250ms ease-out;
}

.header__menu-btn.is_open:before {
  transform: rotate(45deg);
  border-bottom: none;
  top: 10px;
  left: -5px;
}

.header__menu-btn.is_open:after {
  transform: rotate(-45deg);
  top: 12px;
  left: 1px;
}

.header--top {
  height: 100px;
}

@media screen and (max-width: 768px) {
  .header--top {
    height: 60px;
  }
}

.header--top .gnav {
  position: relative;
  top: -5px;
}

@media screen and (max-width: 768px) {
  .header--top .gnav {
    top: 60px;
  }
}

.header--top .header__logo {
  top: 20px;
  left: 33px;
  width: 39px;
}

/* gnav
-------------------------*/
.gnav {
  text-align: center;
}

@media screen and (max-width: 768px) {
  .gnav {
    display: none;
    position: relative;
    background: #fff;
    padding-top: 20px;
    box-sizing: border-box;
    height: 100vh;
    top: 60px;
    padding-bottom: 25px;
    animation: showAnime 250ms ease-out;
  }
  .gnav.is_open {
    display: block;
  }
}

.gnav__ttl {
  display: none;
}

@media screen and (max-width: 768px) {
  .gnav__ttl {
    margin-bottom: 20px;
    padding-bottom: 5px;
    display: inline-block !important;
    font-size: 10px;
    background-image: url("../img/common/line_02.png");
    background-repeat: no-repeat;
    background-position: center bottom;
    background-size: auto 3px;
  }
}

.gnav__list {
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 768px) {
  .gnav__list {
    display: block;
  }
}

.gnav__item {
  position: relative;
}

@media screen and (max-width: 768px) {
  .gnav__item {
    margin-bottom: 20px;
  }
}

.gnav__item:before {
  position: absolute;
  top: 14px;
  left: 0;
  width: 8px;
  height: 15px;
  background-image: url("../img/common/ico_nav_01.png");
  background-size: 100% auto;
  content: "";
}

@media screen and (max-width: 768px) {
  .gnav__item:before {
    display: none;
  }
}

.gnav__item:last-child:after {
  position: absolute;
  top: 14px;
  right: 0;
  width: 8px;
  height: 15px;
  background-image: url("../img/common/ico_nav_01.png");
  background-size: 100% auto;
  content: "";
}

@media screen and (max-width: 768px) {
  .gnav__item:last-child:after {
    display: none;
  }
}

.gnav__en {
  display: block;
  font-size: 14px;
  line-height: 1.4;
}

.gnav__txt {
  display: block;
  font-size: 12px;
  line-height: 1.75;
}

.gnav__link {
  position: relative;
  top: 0;
  display: block;
  padding: 0 35px;
  transition: top 250ms ease-out;
}

.gnav__link:hover {
  top: 5px;
  text-decoration: none;
}

@media screen and (max-width: 768px) {
  .gnav__link:hover {
    top: 0;
  }
}

.gnav__sns {
  display: none;
}

@media screen and (max-width: 768px) {
  .gnav__sns {
    display: block;
    font-size: 12px;
    text-align: center;
  }
}

.gnav__sns-item {
  display: inline-block;
  margin: 0 15px;
}

.sns-btn {
  position: fixed;
  top: 50%;
  right: 20px;
  width: 36px;
  height: 118px;
  background-image: url("../img/common/bg_sns_01.png");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 32px auto;
  z-index: 90;
}

.sns-btn__item {
  position: absolute;
  left: -1px;
  width: 37px;
}

.sns-btn__item--fb {
  top: 2px;
}

.sns-btn__item--in {
  bottom: 2px;
}

.reserve-btn {
  display: block;
  position: fixed;
  top: -10px;
  right: 20px;
  width: 115px;
  height: 156px;
  background-image: url("../img/common/btn_reserve_01.png");
  background-repeat: no-repeat;
  background-position: left bottom;
  background-size: 115px auto;
  z-index: 80;
  transition: top 250ms ease-out;
}

.reserve-btn:hover {
  top: 0;
}

.reserve-btn__txt {
  position: absolute;
  text-align: center;
  bottom: 33px;
  left: 0;
  width: 100%;
  font-size: 12px;
}

.reserve-btn__txt .font-en, .reserve-btn__txt .gnav__ttl, .reserve-btn__txt .gnav__en, .reserve-btn__txt .gnav__sns, .reserve-btn__txt .footer__facebook-ttl, .reserve-btn__txt .footer__phone, .reserve-btn__txt .reserve-sp__phone, .reserve-btn__txt .top-blog__date, .reserve-btn__txt .top-blog__more, .reserve-btn__txt .page-section--menu .container:after, .page-section--menu .reserve-btn__txt .container:after, .reserve-btn__txt .blog-list__date, .reserve-btn__txt .pager, .reserve-btn__txt #wp-calendar, .reserve-btn__txt .wp-pagenavi {
  display: block;
  font-size: 14px;
  line-height: 1;
}

/* breadcrumb
-------------------------*/
.breadcrumb__item--current {
  cursor: default;
  color: #000;
}

/* contents
-------------------------*/
.contents {
  padding-bottom: 85px;
}

@media screen and (max-width: 768px) {
  .contents {
    padding-bottom: 40px;
  }
}

/* footer
-------------------------*/
.footer {
  position: relative;
}

.footer .container {
  position: relative;
  padding-top: 110px;
}

.footer .container:after {
  display: block;
  visibility: hidden;
  clear: both;
  height: 0;
  content: '';
}

@media screen and (max-width: 768px) {
  .footer .container {
    padding-top: 40px;
  }
}

.footer .container:before {
  position: absolute;
  top: 0;
  left: -160px;
  width: 1400px;
  height: 5px;
  position: absolute;
  background-image: url("../img/common/line_02.png");
  background-repeat: no-repeat;
  background-position: left top;
  background-size: auto 5px;
  content: "";
}

.footer__address {
  float: left;
  width: 50%;
}

@media screen and (max-width: 768px) {
  .footer__address {
    float: none;
    width: 100%;
  }
}

.footer__fb-wrap {
  position: relative;
  margin-bottom: 115px;
  margin-left: 50%;
}

@media screen and (max-width: 768px) {
  .footer__fb-wrap {
    max-width: 500px;
    margin: 0 auto 40px;
  }
}

.footer__fb-wrap:before {
  right: -20px;
  bottom: -20px;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #fff;
  background-image: url("../img/common/bg_dot_01.png");
  background-position: right bottom;
  border: 1px solid #000;
  border-radius: 30px;
  box-sizing: border-box;
  content: "";
  border-radius: 10px;
}

@media screen and (max-width: 768px) {
  .footer__fb-wrap:before {
    right: -10px;
    bottom: -10px;
    border-radius: 8px;
  }
}

.footer__facebook {
  position: relative;
  width: 540px;
  height: 380px;
  padding: 20px;
  box-sizing: border-box;
  border: 1px solid #000;
  border-radius: 10px;
  background: #fff;
  z-index: 3;
}

@media screen and (max-width: 768px) {
  .footer__facebook {
    max-width: 500px;
    margin: 0 auto;
    width: 100%;
    height: 340px;
    border-radius: 8px;
  }
}

.footer__facebook .fb-page {
  z-index: 4;
}

@media screen and (max-width: 768px) {
  .footer__facebook .fb-page {
    width: 100% !important;
    height: 300px !important;
  }
}

@media screen and (max-width: 768px) {
  .footer__facebook .fb_iframe_widget span,
  .footer__facebook iframe {
    width: 100% !important;
    height: 300px !important;
  }
}

.footer__facebook-ttl {
  position: absolute;
  top: -14px;
  padding: 0 10px;
  right: 50px;
  display: inline-block;
  background: #fff;
  font-size: 14px;
  z-index: 5;
}

.footer__logo {
  width: 168px;
  float: left;
}

@media screen and (max-width: 768px) {
  .footer__logo {
    float: none;
    width: 110px;
    margin: 0 auto 10px;
  }
}

.footer__txt-wrap {
  margin-left: 205px;
}

@media screen and (max-width: 768px) {
  .footer__txt-wrap {
    margin-left: 0;
    text-align: center;
    margin-bottom: 30px;
  }
}

.footer__txt {
  padding-top: 10px;
  font-size: 14px;
  margin-bottom: 20px;
}

@media screen and (max-width: 768px) {
  .footer__txt {
    padding-top: 0;
    margin-bottom: 1em;
  }
}

.footer__phone {
  display: inline-block;
  margin-bottom: 20px;
  font-size: 22px;
  background-image: url("../img/common/line_02.png");
  background-size: auto 3px;
  background-position: left bottom;
  background-repeat: no-repeat;
  letter-spacing: 0.2em;
}

.footer__btn {
  position: relative;
  display: block;
  text-align: center;
  line-height: 50px;
  height: 50px;
  font-size: 15px;
  width: 270px;
  background: #feecd2;
  border: 1px solid #000;
  border-radius: 10px;
}

@media screen and (max-width: 768px) {
  .footer__btn {
    margin: 30px auto;
  }
}

.footer__btn:hover {
  text-decoration: none;
}

.footer__btn:hover:before {
  left: -13px;
}

.footer__btn:before {
  position: absolute;
  top: 50%;
  left: -23px;
  width: 45px;
  height: 1px;
  background: #000;
  content: "";
  transition: all 250ms ease-out;
}

.footer__map iframe {
  display: block;
  width: 100%;
  height: 480px;
}

@media screen and (max-width: 768px) {
  .footer__map iframe {
    height: 375px;
  }
}

.reserve-sp {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background: #feecd2;
  z-index: 98;
  height: 65px;
  padding: 0 25px;
  box-sizing: border-box;
}

.reserve-sp__txt {
  font-size: 10px;
}

.reserve-sp__phone {
  font-size: 16px;
  padding-top: 14px;
  line-height: 1.4;
}

.reserve-sp__btn {
  position: absolute;
  right: 10px;
  top: 8px;
  text-align: center;
  width: 150px;
  height: 50px;
  background: #fff;
  line-height: 1.4;
  padding-top: 7px;
  border-radius: 10px;
  box-sizing: border-box;
}

.reserve-sp__btn .font-en, .reserve-sp__btn .gnav__ttl, .reserve-sp__btn .gnav__en, .reserve-sp__btn .gnav__sns, .reserve-sp__btn .footer__facebook-ttl, .reserve-sp__btn .footer__phone, .reserve-sp__btn .reserve-sp__phone, .reserve-sp__btn .top-blog__date, .reserve-sp__btn .top-blog__more, .reserve-sp__btn .page-section--menu .container:after, .page-section--menu .reserve-sp__btn .container:after, .reserve-sp__btn .blog-list__date, .reserve-sp__btn .pager, .reserve-sp__btn #wp-calendar, .reserve-sp__btn .wp-pagenavi {
  display: block;
  font-size: 8px;
}

.reserve-sp__btn:hover {
  text-decoration: none;
}

.reserve-sp__btn:before {
  position: absolute;
  top: 50%;
  left: -12px;
  width: 25px;
  height: 1px;
  background: #000;
  content: "";
}

/* ------------------------------------------------
 	common  parts    
  ---------------------------------------------- */
.wrap, .fade {
  display: block;
  text-decoration: none;
}

.wrap:hover, .fade:hover {
  text-decoration: none;
}

.ib {
  display: inline-block;
}

.phoneTxt {
  text-decoration: none !important;
  cursor: default;
}

@media screen and (max-width: 768px) {
  .is_pc {
    display: none;
  }
}

.is_sp {
  display: none !important;
}

@media screen and (max-width: 768px) {
  .is_sp {
    display: block !important;
  }
}

.font-underline {
  text-decoration: underline;
}

.check-list li {
  display: inline-block;
  margin-right: 20px;
  margin-bottom: 5px;
  padding-left: 24px;
  background-image: url("../img/common/ico_check_01.png");
  background-repeat: no-repeat;
  background-position: left 12px;
  background-size: 15px auto;
  font-size: 16px;
}

@media screen and (max-width: 768px) {
  .check-list li {
    font-size: 14px;
    margin-right: 10px;
  }
}

.data-list dt {
  clear: both;
  float: left;
  width: 65px;
  margin-bottom: 5px;
  font-size: 16px;
}

@media screen and (max-width: 768px) {
  .data-list dt {
    font-size: 14px;
    float: none;
    width: auto;
    margin-bottom: 0;
  }
}

.data-list dt span {
  display: inline-block;
  font-size: 14px;
  margin-bottom: 0;
}

@media screen and (max-width: 768px) {
  .data-list dt span {
    font-size: 12px;
    margin-bottom: 0;
  }
}

.data-list dd {
  position: relative;
  padding-left: 126px;
  margin-bottom: 5px;
  font-size: 16px;
}

@media screen and (max-width: 768px) {
  .data-list dd {
    font-size: 14px;
    padding-left: 0;
    margin-bottom: 10px;
  }
}

.data-list dd:before {
  position: absolute;
  top: 16px;
  left: 65px;
  width: 50px;
  height: 1px;
  background: #000;
  content: "";
}

@media screen and (max-width: 768px) {
  .data-list dd:before {
    display: none;
  }
}

.data-list dd span {
  display: inline-block;
  font-size: 14px;
  margin-bottom: 0;
}

@media screen and (max-width: 768px) {
  .data-list dd span {
    font-size: 12px;
    margin-bottom: 0;
  }
}

.data-list--line {
  margin-top: 30px;
  padding-top: 30px;
  border-top: 1px solid #000;
}

@media screen and (max-width: 768px) {
  .data-list--line {
    margin-top: 18px;
    padding-top: 18px;
  }
}

.data-list--rent dt {
  width: 195px;
}

@media screen and (max-width: 768px) {
  .data-list--rent dt {
    width: 100%;
  }
}

.data-list--rent dd {
  padding-left: 260px;
}

@media screen and (max-width: 768px) {
  .data-list--rent dd {
    padding-left: 0;
  }
}

.data-list--rent dd:before {
  left: 195px;
}

@media screen and (max-width: 768px) {
  .data-list--rent dd:before {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .data-list--price dt {
    width: 155px;
    float: left;
  }
}

@media screen and (max-width: 768px) {
  .data-list--price dd {
    padding-left: 155px;
  }
}

.data-list--price dd:before {
  left: 195px;
}

@media screen and (max-width: 768px) {
  .data-list--price dd:before {
    display: none;
  }
}

.data-list--block dt {
  margin-bottom: 22px;
}

@media screen and (max-width: 768px) {
  .data-list--block dt {
    margin-bottom: 0;
  }
}

.data-list--block dd {
  margin-bottom: 22px;
}

@media screen and (max-width: 768px) {
  .data-list--block dd {
    margin-bottom: 15px;
  }
}

.note-txt {
  margin-top: 10px;
  font-size: 14px;
}

@media screen and (max-width: 768px) {
  .note-txt {
    margin-bottom: 10px;
    font-size: 12px;
  }
}

.note-list {
  text-align: right;
}

.note-list li {
  display: inline-block;
  white-space: nowrap;
  margin-right: 10px;
}

@media screen and (max-width: 768px) {
  .note-list li {
    font-size: 10px;
  }
}

.note-list--mb {
  margin-bottom: 100px;
}

@media screen and (max-width: 768px) {
  .note-list--mb {
    margin-bottom: 50px;
  }
}

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
}

.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}

[dir="rtl"] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

/* ------------------------------------------------
  TOP
---------------------------------------------- */
.mv {
  position: relative;
  margin-bottom: 100px;
  padding: 0 75px 0;
  height: calc(100vh - 140px);
  max-height: 1600px;
}

@media screen and (max-width: 768px) {
  .mv {
    margin-bottom: 50px;
    padding: 0 25px 0;
    height: auto;
  }
}

.mv:before {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 12%;
  max-width: 168px;
  height: 240px;
  transform: translate(-50%, -50%);
  background-image: url("../img/common/logo_01.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 100% auto;
  content: "";
  z-index: 80;
}

@media screen and (max-width: 768px) {
  .mv:before {
    width: 140px;
    height: 200px;
  }
}

.mv__wrap {
  position: relative;
  z-index: 1;
}

.mv__wrap:before {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #fff;
  background-image: url("../img/common/bg_dot_01.png");
  background-position: right bottom;
  border: 1px solid #000;
  border-radius: 30px;
  box-sizing: border-box;
  content: "";
  right: -40px;
  bottom: -39px;
  width: 50%;
}

@media screen and (max-width: 768px) {
  .mv__wrap:before {
    right: -2%;
    bottom: -2%;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #fff;
    background-image: url("../img/common/bg_dot_01.png");
    background-position: right bottom;
    border: 1px solid #000;
    border-radius: 30px;
    box-sizing: border-box;
    content: "";
    background-size: auto;
    background-repeat: repeat;
    border-radius: 7px;
  }
}

.mv__wrap:after {
  position: absolute;
  bottom: -39px;
  left: 40px;
  background-image: url("../img/top/bg_mv_bottom.png");
  background-repeat: no-repeat;
  background-position: left bottom;
  background-size: auto 100%;
  content: "";
  width: calc(100% - 40px);
  height: 40px;
  z-index: 10;
}

@media screen and (max-width: 768px) {
  .mv__wrap:after {
    display: none;
  }
}

.mv__list {
  z-index: 20;
  border-radius: 30px;
  background: #fff;
}

.mv__item {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  border-radius: 30px;
  border: 1px solid #000;
  box-sizing: border-box;
  height: calc(100vh - 180px);
  max-height: 1600px;
  overflow: hidden;
}

@media screen and (max-width: 768px) {
  .mv__item {
    border-radius: 7px;
    height: auto;
  }
}

.mv__img {
  display: none !important;
}

@media screen and (max-width: 768px) {
  .mv__img {
    display: block !important;
  }
}

.mv__txt {
  position: absolute;
  padding-bottom: 5px;
  padding-left: 5px;
  font-size: 17px;
  color: #fff;
  background-image: url("../img/top/line_mv_01.png");
  background-size: 278px auto;
  background-repeat: no-repeat;
  background-position: left bottom;
}

.mv__txt--01 {
  top: 43%;
  left: 12%;
}

.mv__txt--02 {
  top: 45%;
  right: 17%;
}

.mv__en {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 33px;
  width: 11px;
}

.mv__scroll {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -40px;
  width: 77px;
  height: 80px;
  text-align: center;
  font-size: 10px;
  z-index: 3;
  overflow: hidden;
}

.mv__scroll:after {
  animation: scrollbar 1.8s infinite linear;
  background: #000;
  content: "";
  height: 90px;
  left: 50%;
  position: absolute;
  top: 0;
  width: 1px;
  z-index: -1;
}

.mv__scroll-txt {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: block;
  width: 77px;
  height: 24px;
  background: #fff;
  z-index: 3;
}

@keyframes scrollbar {
  0% {
    transform: translateY(-100px);
  }
  100% {
    transform: translateY(100px);
  }
}

.top-container {
  width: 1200px;
  margin: 0 auto;
}

@media screen and (max-width: 768px) {
  .top-container {
    width: 100%;
    padding: 0 25px;
    box-sizing: border-box;
  }
}

.top-slide {
  position: relative;
  margin-bottom: 77px;
  padding-bottom: 85px;
}

@media screen and (max-width: 768px) {
  .top-slide {
    margin-bottom: 65px;
  }
}

.top-slide:before {
  position: absolute;
  top: 0;
  left: 50%;
  height: 159px;
  width: 206px;
  margin-left: -300px;
  background-image: url("../img/common/deco_01.png");
  background-position: left top;
  background-repeat: no-repeat;
  background-size: 206px auto;
  content: "";
  z-index: 70;
}

@media screen and (max-width: 768px) {
  .top-slide:before {
    display: none;
  }
}

.top-slide .top-container {
  padding-top: 65px;
  position: relative;
}

.top-slide .top-container:after {
  display: block;
  visibility: hidden;
  clear: both;
  height: 0;
  content: '';
}

@media screen and (max-width: 768px) {
  .top-slide .top-container {
    padding-top: 0;
  }
}

.top-slide .top-container:before {
  position: absolute;
  bottom: -70px;
  left: -250px;
  height: 159px;
  width: 206px;
  background-image: url("../img/common/deco_01.png");
  background-position: left top;
  background-repeat: no-repeat;
  background-size: 206px auto;
  content: "";
  z-index: 70;
}

@media screen and (max-width: 768px) {
  .top-slide .top-container:before {
    display: none;
  }
}

.top-slide__txt-wrap {
  padding-top: 20px;
  float: left;
  width: 300px;
}

@media screen and (max-width: 768px) {
  .top-slide__txt-wrap {
    float: none;
    width: 100%;
    padding-top: 0;
    margin-bottom: 30px;
  }
}

.top-slide__ttl {
  margin-bottom: 10px;
  font-size: 20px;
}

@media screen and (max-width: 768px) {
  .top-slide__ttl {
    font-size: 18px;
  }
}

.top-slide__en {
  position: relative;
  padding-bottom: 25px;
  margin-bottom: 15px;
  font-size: 10px;
  line-height: 2.5;
}

.top-slide__en:before {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 40px;
  height: 3px;
  background-image: url("../img/common/line_02.png");
  background-repeat: no-repeat;
  background-position: left center;
  background-size: auto 3px;
  content: "";
}

.top-slide__item {
  position: relative;
  border-radius: 30px;
  overflow: hidden;
  background: #fff;
  border: 1px solid #000;
  box-sizing: border-box;
  z-index: 30;
}

@media screen and (max-width: 768px) {
  .top-slide__item {
    border-radius: 8px;
  }
}

.top-slide__wrap {
  position: relative;
  float: right;
  width: 900px;
  height: 460px;
  right: -50px;
}

@media screen and (max-width: 768px) {
  .top-slide__wrap {
    float: none;
    right: 0;
    width: 100%;
    height: auto;
  }
}

.top-slide__wrap:before {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #fff;
  background-image: url("../img/common/bg_dot_01.png");
  background-position: right bottom;
  border: 1px solid #000;
  border-radius: 30px;
  box-sizing: border-box;
  content: "";
  bottom: -30px;
  right: -30px;
}

@media screen and (max-width: 768px) {
  .top-slide__wrap:before {
    right: -10px;
    bottom: -10px;
    border-radius: 8px;
  }
}

.top-slide__list {
  border-radius: 30px;
  background: #fff;
}

.top-slide .slick-dots {
  position: absolute;
  right: 166px;
  width: 260px;
  height: 38px;
  bottom: -48px;
  background: #fff;
  text-align: center;
}

@media screen and (max-width: 768px) {
  .top-slide .slick-dots {
    right: inherit;
    left: 50%;
    margin-left: -130px;
  }
}

.top-slide .slick-dots li {
  display: inline-block;
  margin: 0 8px;
  padding-top: 14px;
}

.top-slide .slick-dots button {
  cursor: pointer;
  text-indent: -9999px;
  overflow: hidden;
  position: relative;
  -webkit-appearance: none;
  width: 10px;
  height: 10px;
  display: block;
  border: none;
  background: #fff;
}

.top-slide .slick-dots button:before {
  position: absolute;
  top: 0;
  left: 2px;
  background-image: url("../img/common/ico_dot_01.png");
  background-repeat: no-repeat;
  background-position: left top;
  background-size: 10px auto;
  text-indent: 0px;
  width: 10px;
  height: 10px;
  content: "";
}

.top-slide .slick-dots .slick-active button:before {
  background-image: url("../img/common/ico_dot_active_01.png");
}

.top-slide .slick-arrow {
  position: absolute;
  bottom: -35px;
  z-index: 60;
  cursor: pointer;
}

.top-slide .prev-arrow {
  width: 31px;
  right: 376px;
}

@media screen and (max-width: 768px) {
  .top-slide .prev-arrow {
    right: inherit;
    left: 50%;
    margin-left: -100px;
  }
}

.top-slide .next-arrow {
  width: 31px;
  right: 180px;
}

@media screen and (max-width: 768px) {
  .top-slide .next-arrow {
    right: inherit;
    left: 50%;
    margin-left: 70px;
  }
}

.top-service {
  margin-bottom: 78px;
}

@media screen and (max-width: 768px) {
  .top-service {
    margin-bottom: 15px;
  }
}

.top-service .top-container:after {
  display: block;
  visibility: hidden;
  clear: both;
  height: 0;
  content: '';
}

.top-service__ttl {
  position: relative;
  font-size: 16px;
  float: left;
}

@media screen and (max-width: 768px) {
  .top-service__ttl {
    float: none;
    margin-bottom: 50px;
    font-size: 14px;
    text-align: center;
  }
}

.top-service__ttl:before {
  position: absolute;
  left: -35px;
  top: 50%;
  width: 20px;
  height: 1px;
  background: #000;
  content: "";
}

@media screen and (max-width: 768px) {
  .top-service__ttl:before {
    width: 1px;
    height: 20px;
    left: 50%;
    top: 35px;
  }
}

.top-service__catch {
  position: relative;
  top: -15px;
  width: 667px;
  float: right;
  right: -112px;
  margin-bottom: 50px;
}

.top-service__menu {
  clear: both;
  padding-bottom: 10px;
  background-image: url("../img/top/bg_menu_01.png");
  background-size: 1193px auto;
  background-position: center top;
  background-repeat: no-repeat;
}

.top-service__menu:after {
  display: block;
  visibility: hidden;
  clear: both;
  height: 0;
  content: '';
}

@media screen and (max-width: 768px) {
  .top-service__menu {
    background: none;
  }
}

.top-service__item {
  position: relative;
  width: 550px;
}

@media screen and (max-width: 768px) {
  .top-service__item {
    width: 100%;
    margin-bottom: 32px !important;
  }
}

.top-service__item:before {
  right: -20px;
  bottom: -20px;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #fff;
  background-image: url("../img/common/bg_dot_01.png");
  background-position: right bottom;
  border: 1px solid #000;
  border-radius: 30px;
  box-sizing: border-box;
  content: "";
  z-index: 2;
  border-radius: 32px;
}

@media screen and (max-width: 768px) {
  .top-service__item:before {
    right: -10px;
    bottom: -10px;
    border-radius: 8px;
  }
}

.top-service__item--01 {
  float: left;
  margin-bottom: 120px;
  height: 480px;
}

@media screen and (max-width: 768px) {
  .top-service__item--01 {
    float: none;
    height: auto;
  }
}

.top-service__item--01 .top-service__item-ttl {
  width: 260px;
  height: 127px;
  padding-top: 45px;
  background-image: url("../img/top/bg_ttl_service_01_pc.png");
}

@media screen and (max-width: 768px) {
  .top-service__item--01 .top-service__item-ttl {
    width: 220px;
    height: 89px;
    padding-top: 28px;
    background-image: url("../img/top/bg_ttl_service_01_sp.png");
  }
}

.top-service__item--02 {
  margin-top: 80px;
  float: right;
  margin-bottom: 90px;
  height: 480px;
}

@media screen and (max-width: 768px) {
  .top-service__item--02 {
    float: none;
    height: auto;
    margin-top: 0;
  }
}

.top-service__item--02 .top-service__item-ttl {
  width: 260px;
  height: 127px;
  padding-top: 45px;
  background-image: url("../img/top/bg_ttl_service_02_pc.png");
}

@media screen and (max-width: 768px) {
  .top-service__item--02 .top-service__item-ttl {
    width: 220px;
    height: 89px;
    padding-top: 28px;
    background-image: url("../img/top/bg_ttl_service_02_sp.png");
  }
}

.top-service__item--03 {
  clear: both;
  float: right;
  width: 550px;
  height: 200px;
}

@media screen and (max-width: 768px) {
  .top-service__item--03 {
    float: none;
    width: 100%;
    height: auto;
  }
}

.top-service__item--03 .top-service__img-wrap {
  border: none;
  border-left: 1px solid #000;
  border-radius: 0;
}

.top-service__item--03 .top-service__link {
  display: table;
  width: 550px;
  border: 1px solid #000;
  border-radius: 30px;
  background: #fff;
  overflow: hidden;
}

@media screen and (max-width: 768px) {
  .top-service__item--03 .top-service__link {
    width: 100%;
    border-radius: 8px;
    overflow: hidden;
  }
}

.top-service__item--03 .top-service__item-ttl {
  display: table-cell;
  position: relative;
  top: 0;
  left: 0;
  text-align: left;
  transform: translate(0, 0);
  float: none;
  padding-left: 48px;
  vertical-align: middle;
}

@media screen and (max-width: 768px) {
  .top-service__item--03 .top-service__item-ttl {
    font-size: 16px;
    padding-left: 30px;
  }
}

.top-service__item--03 .top-service__img-wrap {
  display: table-cell;
  width: 275px;
}

@media screen and (max-width: 768px) {
  .top-service__item--03 .top-service__img-wrap {
    width: 40%;
  }
}

.top-service__item--04 {
  float: left;
  margin-top: -50px;
  width: 550px;
  height: 200px;
}

@media screen and (max-width: 768px) {
  .top-service__item--04 {
    float: none;
    width: 100%;
    height: auto;
    margin-top: 0;
  }
}

.top-service__item--04 .top-service__img-wrap {
  border: none;
  border-left: 1px solid #000;
  border-radius: 0;
}

.top-service__item--04 .top-service__link {
  display: table;
  border: 1px solid #000;
  border-radius: 30px;
  background: #fff;
  width: 550px;
  overflow: hidden;
}

@media screen and (max-width: 768px) {
  .top-service__item--04 .top-service__link {
    border-radius: 8px;
    width: 100%;
  }
}

.top-service__item--04 .top-service__item-ttl {
  display: table-cell;
  position: relative;
  top: 0;
  left: 0;
  text-align: left;
  transform: translate(0, 0);
  float: none;
  padding-left: 48px;
  vertical-align: middle;
}

@media screen and (max-width: 768px) {
  .top-service__item--04 .top-service__item-ttl {
    font-size: 16px;
    padding-left: 30px;
  }
}

.top-service__item--04 .top-service__img-wrap {
  display: table-cell;
  width: 275px;
}

@media screen and (max-width: 768px) {
  .top-service__item--04 .top-service__img-wrap {
    width: 40%;
  }
}

.top-service__img-wrap {
  position: relative;
  z-index: 3;
  opacity: 1;
  transition: opacity 300ms;
  border: 1px solid #000;
  border-radius: 32px 32px 0 0;
  overflow: hidden;
}

@media screen and (max-width: 768px) {
  .top-service__img-wrap {
    border-radius: 8px 8px 0 0;
    overflow: hidden;
  }
}

.top-service__img {
  position: relative;
}

.top-service__item-ttl {
  position: absolute;
  font-size: 18px;
  text-align: center;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  line-height: 1.6;
  z-index: 4;
  box-sizing: border-box;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 100% auto;
}

@media screen and (max-width: 768px) {
  .top-service__item-ttl {
    font-size: 17px;
  }
}

.top-service__item-ttl .font-en, .top-service__item-ttl .gnav__ttl, .top-service__item-ttl .gnav__en, .top-service__item-ttl .gnav__sns, .top-service__item-ttl .footer__facebook-ttl, .top-service__item-ttl .footer__phone, .top-service__item-ttl .reserve-sp__phone, .top-service__item-ttl .top-blog__date, .top-service__item-ttl .top-blog__more, .top-service__item-ttl .page-section--menu .container:after, .page-section--menu .top-service__item-ttl .container:after, .top-service__item-ttl .blog-list__date, .top-service__item-ttl .pager, .top-service__item-ttl #wp-calendar, .top-service__item-ttl .wp-pagenavi {
  font-size: 8px;
  display: block;
}

.top-service__txt {
  position: relative;
  z-index: 4;
  padding: 30px 40px 0;
  border: 1px solid #000;
  border-radius: 0 0 30px 30px;
  box-sizing: border-box;
  height: 152px;
  border-top: none;
  background: #fff;
}

@media screen and (max-width: 768px) {
  .top-service__txt {
    padding: 20px 25px;
    height: auto;
    border-radius: 0 0 10px 10px;
  }
}

.top-service__link {
  position: relative;
  display: block;
  z-index: 8;
  background: #fff;
  border-radius: 30px;
}

.top-service__link:after {
  display: block;
  visibility: hidden;
  clear: both;
  height: 0;
  content: '';
}

.top-service__link:hover {
  text-decoration: none;
}

.top-service__link:hover .top-service__img-wrap {
  opacity: 0.7;
}

.top-gallery {
  margin-bottom: 156px;
  padding: 0 60px;
}

@media screen and (max-width: 1200px) {
  .top-gallery {
    padding: 0 25px;
  }
}

@media screen and (max-width: 768px) {
  .top-gallery {
    margin-bottom: 85px;
  }
}

.top-gallery__ttl {
  position: relative;
  margin-bottom: 30px;
  padding-left: 10px;
}

@media screen and (max-width: 768px) {
  .top-gallery__ttl {
    margin-bottom: 0;
    padding-bottom: 5px;
    padding-left: 15px;
    font-size: 10px;
  }
}

.top-gallery__ttl:before {
  position: absolute;
  top: -30px;
  left: -30px;
  width: 4px;
  height: 112px;
  background-image: url("../img/common/line_01.png");
  background-repeat: no-repeat;
  background-size: 4px auto;
  background-position: left top;
  content: "";
}

@media screen and (max-width: 768px) {
  .top-gallery__ttl:before {
    left: 0;
    height: 30px;
    top: -5px;
  }
}

.top-gallery__item {
  position: relative;
  width: 100%;
  border-radius: 20px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

@media screen and (max-width: 768px) {
  .top-gallery__item {
    width: 100%;
    left: 0;
    border-radius: 8px;
  }
}

.top-gallery__item:before {
  right: -30px;
  bottom: -30px;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #fff;
  background-image: url("../img/common/bg_dot_01.png");
  background-position: right bottom;
  border: 1px solid #000;
  border-radius: 30px;
  box-sizing: border-box;
  content: "";
  z-index: -1;
  border-radius: 20px;
}

@media screen and (max-width: 768px) {
  .top-gallery__item:before {
    right: -10px;
    bottom: -10px;
    border-radius: 8px;
  }
}

.top-gallery__img {
  position: relative;
  z-index: 3;
  border: 1px solid #000;
  box-sizing: border-box;
  border-radius: 20px;
  overflow: hidden;
}

@media screen and (max-width: 768px) {
  .top-gallery__img {
    border-radius: 8px;
  }
}

@media screen and (max-width: 768px) {
  .top-blog {
    margin-bottom: 45px;
  }
}

.top-blog .top-container {
  position: relative;
}

@media screen and (max-width: 1200px) {
  .top-blog .top-container {
    width: 1080px;
    margin: 0 auto;
  }
}

@media screen and (max-width: 768px) {
  .top-blog .top-container {
    width: 100%;
  }
}

.top-blog__ttl {
  position: relative;
  font-size: 16px;
  margin-bottom: 55px;
}

@media screen and (max-width: 768px) {
  .top-blog__ttl {
    text-align: center;
    font-size: 14px;
    padding-bottom: 30px;
    margin-bottom: 15px;
  }
}

.top-blog__ttl:before {
  position: absolute;
  left: -35px;
  top: 50%;
  width: 20px;
  height: 1px;
  background: #000;
  content: "";
}

@media screen and (max-width: 768px) {
  .top-blog__ttl:before {
    width: 1px;
    height: 20px;
    left: 50%;
    bottom: 0;
    top: inherit;
  }
}

.top-blog__list:after {
  display: block;
  visibility: hidden;
  clear: both;
  height: 0;
  content: '';
}

.top-blog__link {
  display: block;
  opacity: 1;
  transition: opacity 250ms ease-out;
}

.top-blog__link:hover {
  opacity: .7;
}

@media screen and (max-width: 768px) {
  .top-blog__link:hover {
    opacity: 1;
  }
}

.top-blog__link:after {
  display: block;
  visibility: hidden;
  clear: both;
  height: 0;
  content: '';
}

.top-blog__link:hover {
  text-decoration: none;
}

.top-blog__item {
  position: relative;
  float: left;
  width: 332px;
  margin-left: 102px;
}

@media screen and (max-width: 1200px) {
  .top-blog__item {
    margin-left: 42px;
  }
}

@media screen and (max-width: 768px) {
  .top-blog__item {
    float: none;
    width: 100%;
    margin-left: 0;
    margin-bottom: 24px;
  }
}

.top-blog__item:first-child {
  margin-left: 0;
}

.top-blog__img-wrap {
  position: relative;
  z-index: 2;
  margin-bottom: 30px;
  width: 332px;
  height: 200px;
}

@media screen and (max-width: 768px) {
  .top-blog__img-wrap {
    width: 100px;
    height: auto;
    border-radius: 3px;
    overflow: hidden;
    float: left;
    margin-bottom: 0;
  }
}

.top-blog__img-wrap:before {
  right: -10px;
  bottom: -10px;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #fff;
  background-image: url("../img/common/bg_dot_02.png");
  background-position: right bottom;
  border: 1px solid #000;
  border-radius: 30px;
  box-sizing: border-box;
  content: "";
  border-radius: 10px;
  z-index: 2;
  content: "";
}

@media screen and (max-width: 768px) {
  .top-blog__img-wrap:before {
    display: none;
  }
}

.top-blog__img {
  position: relative;
  z-index: 3;
  border: 1px solid #000;
  border-radius: 10px;
  overflow: hidden;
}

@media screen and (max-width: 768px) {
  .top-blog__img {
    border-radius: 3px;
  }
}

.top-blog__item-ttl {
  height: 60px;
}

@media screen and (max-width: 768px) {
  .top-blog__item-ttl {
    padding-top: 24px;
    margin-left: 112px;
    height: auto;
  }
}

@media screen and (max-width: 768px) {
  .top-blog__meta {
    position: absolute;
    top: 0;
    left: 112px;
  }
}

.top-blog__date {
  font-size: 10px;
}

@media screen and (max-width: 768px) {
  .top-blog__date {
    line-height: 1.4;
    vertical-align: middle;
  }
}

.top-blog__category {
  position: absolute;
  top: 20px;
  left: -7px;
  display: block;
  width: 110px;
  height: 28px;
  line-height: 28px;
  background: #fff;
  font-size: 10px;
  z-index: 4;
  text-align: center;
  border-radius: 0 6px 6px 0;
  border: 1px solid #000;
  border-left: none;
  box-sizing: border-box;
}

@media screen and (max-width: 768px) {
  .top-blog__category {
    display: inline-block;
    position: relative;
    top: 0;
    left: 0;
    height: auto;
    line-height: 1.4;
    border: 1px solid #000;
    border-radius: 4px;
    width: 67px;
    vertical-align: middle;
  }
}

.top-blog__more {
  display: inline-block;
  padding: 0 6px 3px 6px;
  position: absolute;
  top: 0;
  right: 0;
  font-size: 12px;
  background-image: url("../img/common/line_02.png");
  background-size: auto 3px;
  background-position: -10px bottom;
  background-repeat: no-repeat;
  transition: background 300ms;
}

@media screen and (max-width: 768px) {
  .top-blog__more {
    position: relative;
    text-align: center;
    display: block;
    width: 90px;
    margin: 0 auto;
    padding: 0 2px 3px 2px;
  }
}

.top-blog__more:hover {
  text-decoration: none;
  background-position: 0 bottom;
}

@media screen and (max-width: 768px) {
  .top-blog__more:hover {
    background-position: -10px bottom;
  }
}

/* ------------------------------------------------
  page
---------------------------------------------- */
.page-ttl {
  padding-bottom: 100px;
  text-align: center;
  font-size: 22px;
  background-image: url("../img/common/bg_page_ttl_01.png");
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: 358px auto;
}

@media screen and (max-width: 768px) {
  .page-ttl {
    margin-bottom: 45px;
    padding-top: 30px;
    font-size: 20px;
    padding-bottom: 50px;
    background-size: 200px auto;
  }
}

.page-ttl-en {
  display: block;
  font-size: 10px;
  line-height: 1.4;
}

.page-top {
  position: relative;
  margin-bottom: 142px;
}

@media screen and (max-width: 768px) {
  .page-top {
    margin-bottom: 60px;
  }
}

.page-top:before {
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: 324px;
  width: 206px;
  height: 159px;
  background-image: url("../img/common/deco_01.png");
  background-repeat: no-repeat;
  background-size: 100% auto;
  content: "";
  z-index: 6;
}

@media screen and (max-width: 768px) {
  .page-top:before {
    display: none;
  }
}

.page-top .container {
  position: relative;
  padding-top: 80px;
}

.page-top .container:after {
  display: block;
  visibility: hidden;
  clear: both;
  height: 0;
  content: '';
}

@media screen and (max-width: 768px) {
  .page-top .container {
    padding-top: 0;
  }
}

.page-top .container:before {
  position: absolute;
  top: 410px;
  left: -308px;
  height: 159px;
  width: 206px;
  background-image: url(../img/common/deco_01.png);
  background-position: left top;
  background-repeat: no-repeat;
  background-size: 206px auto;
  content: "";
  z-index: 70;
}

@media screen and (max-width: 768px) {
  .page-top .container:before {
    display: none;
  }
}

.page-top__txt-wrap {
  float: left;
  width: 390px;
}

@media screen and (max-width: 768px) {
  .page-top__txt-wrap {
    float: none;
    width: 100%;
    margin-bottom: 30px;
  }
}

.page-top__ttl {
  padding-top: 20px;
  font-size: 20px;
  position: relative;
  padding-bottom: 25px;
  margin-bottom: 30px;
}

@media screen and (max-width: 768px) {
  .page-top__ttl {
    font-size: 18px;
    padding-top: 0;
  }
}

.page-top__ttl:before {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 40px;
  height: 3px;
  background-image: url("../img/common/line_02.png");
  background-repeat: no-repeat;
  background-position: left center;
  background-size: auto 3px;
  content: "";
}

.page-top__ttl--center {
  text-align: center;
}

@media screen and (max-width: 768px) {
  .page-top__ttl--center {
    text-align: left;
  }
}

.page-top__ttl--center:before {
  left: 50%;
  margin-left: -20px;
}

@media screen and (max-width: 768px) {
  .page-top__ttl--center:before {
    left: 0;
    margin-left: 0;
  }
}

.page-top__txt {
  margin-bottom: 90px;
}

@media screen and (max-width: 768px) {
  .page-top__txt {
    margin-bottom: 40px;
  }
}

.page-top__txt--center {
  text-align: center;
}

@media screen and (max-width: 768px) {
  .page-top__txt--center {
    text-align: left;
  }
}

.page-top__txt--mt {
  margin-top: 100px;
  font-size: 20px;
}

@media screen and (max-width: 768px) {
  .page-top__txt--mt {
    margin-top: 0;
    font-size: 18px;
  }
}

.page-top__img-wrap {
  position: relative;
  width: 750px;
  left: 30px;
  margin-left: 390px;
  box-sizing: border-box;
}

.page-top__img-wrap img {
  border: 1px solid #000;
  border-radius: 20px;
}

@media screen and (max-width: 768px) {
  .page-top__img-wrap img {
    border-radius: 8px;
  }
}

@media screen and (max-width: 768px) {
  .page-top__img-wrap {
    margin-left: 0;
    left: 0;
    width: 100%;
  }
}

.page-top__img-wrap:before {
  right: -30px;
  bottom: -30px;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #fff;
  background-image: url("../img/common/bg_dot_01.png");
  background-position: right bottom;
  border: 1px solid #000;
  border-radius: 30px;
  box-sizing: border-box;
  content: "";
  border-radius: 20px;
}

@media screen and (max-width: 768px) {
  .page-top__img-wrap:before {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #fff;
    background-image: url("../img/common/bg_dot_02.png");
    background-position: right bottom;
    border: 1px solid #000;
    border-radius: 30px;
    box-sizing: border-box;
    content: "";
    right: -10px;
    bottom: -10px;
    border-radius: 8px;
  }
}

.page-top__img-wrap--center {
  width: auto;
  left: 0;
  margin-left: 0;
}

.page-top__img {
  position: relative;
  z-index: 3;
}

.page-top__contact {
  text-align: center;
  margin-top: -20px;
}

.page-top__phone {
  display: inline-block;
  padding: 0 20px;
  margin-bottom: 30px;
  font-size: 25px;
  background-image: url(../img/common/line_02.png);
  background-size: auto 5px;
  background-position: left bottom;
  background-repeat: no-repeat;
}

@media screen and (max-width: 768px) {
  .page-top__phone {
    background-size: auto 3px;
  }
}

.page-top__info li {
  display: inline-block;
  margin: 0 40px;
}

@media screen and (max-width: 768px) {
  .page-top__info li {
    display: block;
    margin: 0;
  }
}

.page-top--rent .page-top__txt-wrap {
  padding-top: 70px;
  width: 510px;
}

@media screen and (max-width: 768px) {
  .page-top--rent .page-top__txt-wrap {
    width: 100%;
    padding-top: 0;
  }
}

.page-top--rent .page-top__img-wrap {
  margin-top: 20px;
  width: 570px;
  margin-left: 570px;
}

@media screen and (max-width: 768px) {
  .page-top--rent .page-top__img-wrap {
    width: 100%;
    margin-left: 0;
    margin-bottom: 80px;
  }
}

.page-top--reserve {
  margin-bottom: 60px;
}

.page-top--reserve:before {
  display: none;
}

.page-top--archive {
  margin-bottom: 0;
}

.page-top--archive .container {
  padding: 0;
}

@media screen and (max-width: 768px) {
  .page-top--archive .container {
    padding: 0 25px;
  }
}

.page-top--archive .page-top__ttl {
  margin-bottom: 0;
}

@media screen and (max-width: 768px) {
  .page-top--archive .page-top__ttl {
    margin-bottom: 20px;
  }
}

.page-top--school:before {
  display: none;
}

.half-list {
  clear: both;
  margin-bottom: 10px;
  padding: 20px 30px 0;
}

.half-list:after {
  display: block;
  visibility: hidden;
  clear: both;
  height: 0;
  content: '';
}

@media screen and (max-width: 768px) {
  .half-list {
    padding: 0;
    margin-bottom: 45px;
  }
  .half-list:last-child {
    margin-bottom: 0;
  }
}

.half-list__item {
  position: relative;
  width: 50%;
  float: left;
  margin-bottom: 70px;
}

@media screen and (max-width: 768px) {
  .half-list__item {
    width: 100%;
    float: none;
    margin-bottom: 40px;
  }
  .half-list__item:last-child {
    margin-bottom: 0;
  }
}

.half-list__item:nth-child(odd) {
  left: -30px;
}

@media screen and (max-width: 768px) {
  .half-list__item:nth-child(odd) {
    left: 0;
  }
}

.half-list__item:nth-child(even) {
  left: 30px;
}

@media screen and (max-width: 768px) {
  .half-list__item:nth-child(even) {
    left: 0;
  }
}

@media screen and (max-width: 768px) {
  .half-list__item--sp-mb {
    margin-bottom: 110px;
  }
}

.half-list__item .page-section__img-wrap {
  margin-bottom: 0;
}

.half-list__ttl-wrap {
  position: absolute;
  top: -21px;
  left: -20px;
  min-width: 260px;
}

@media screen and (max-width: 768px) {
  .half-list__ttl-wrap {
    left: 50%;
    margin-left: -130px;
  }
}

.half-list__ttl-wrap:before {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #fff;
  background-image: url("../img/common/bg_dot_01.png");
  background-position: right bottom;
  border: 1px solid #000;
  border-radius: 30px;
  box-sizing: border-box;
  content: "";
  right: -5px;
  bottom: -5px;
  z-index: 2;
  border-radius: 5px;
}

@media screen and (max-width: 768px) {
  .half-list__ttl-wrap--sp-br {
    top: -44px;
  }
  .half-list__ttl-wrap--sp-br .half-list__ttl {
    padding: 6px 30px;
  }
  .half-list__ttl-wrap--sp-br + .half-list__container {
    padding: 45px 25px 10px;
  }
}

@media screen and (max-width: 768px) {
  .half-list__ttl-wrap--sp-br-2 {
    top: -82px;
  }
  .half-list__ttl-wrap--sp-br-2 .half-list__ttl {
    padding: 7px 30px;
  }
  .half-list__ttl-wrap--sp-br-2 + .half-list__container {
    padding: 45px 25px 10px;
  }
}

.half-list__ttl {
  position: relative;
  background: #feecd2;
  text-align: center;
  border: 1px solid #000;
  border-radius: 5px;
  box-sizing: border-box;
  padding: 5px 30px;
  z-index: 3;
  white-space: nowrap;
}

.half-list__ttl span {
  font-size: 12px;
}

.half-list__sub-ttl {
  font-size: 18px;
}

.half-list__sub-ttl + .data-list {
  margin-top: 20px;
}

@media screen and (max-width: 768px) {
  .half-list__sub-ttl + .data-list {
    margin-top: 10px;
  }
}

.half-list__txt {
  margin-bottom: 20px;
  font-size: 16px;
}

@media screen and (max-width: 768px) {
  .half-list__txt {
    font-size: 14px;
    margin-bottom: 10px;
  }
}

.half-list__container {
  position: relative;
  border: 1px solid #000;
  border-radius: 10px;
  padding: 40px 40px 20px;
  box-sizing: border-box;
}

@media screen and (max-width: 768px) {
  .half-list__container {
    padding: 35px 25px 20px;
    border-radius: 7px;
  }
}

.half-list__container:before {
  position: absolute;
  top: -5px;
  left: 240px;
  width: 10px;
  height: 10px;
  content: "";
  background: #fff;
}

@media screen and (max-width: 768px) {
  .half-list__container:before {
    display: none;
  }
}

.half-list__container:after {
  position: absolute;
  top: 20px;
  left: -5px;
  width: 10px;
  height: 10px;
  content: "";
  background: #fff;
}

@media screen and (max-width: 768px) {
  .half-list__container:after {
    display: none;
  }
}

.half-list__only-txt {
  margin-top: 140px;
}

@media screen and (max-width: 768px) {
  .half-list__only-txt {
    margin-top: 0;
  }
}

.half-list--txt-data {
  padding: 80px 30px 0;
}

@media screen and (max-width: 768px) {
  .half-list--txt-data {
    padding: 0;
  }
}

.half-list--txt-data .page-section__sub-ttl {
  margin-top: -40px;
}

@media screen and (max-width: 768px) {
  .half-list--txt-data .page-section__sub-ttl {
    margin-top: 0;
  }
}

@media screen and (max-width: 768px) {
  .half-list + .half-list {
    margin-top: 60px;
  }
}

.third-list {
  margin-bottom: 135px;
  padding: 0 60px;
}

.third-list:after {
  display: block;
  visibility: hidden;
  clear: both;
  height: 0;
  content: '';
}

@media screen and (max-width: 768px) {
  .third-list {
    margin-bottom: 45px;
    padding: 0;
  }
}

.third-list__item {
  position: relative;
  float: left;
  width: 33.3%;
  text-align: center;
}

@media screen and (max-width: 768px) {
  .third-list__item {
    width: 100%;
    margin-bottom: 50px;
  }
}

.third-list__item:nth-child(3n) {
  left: 60px;
}

@media screen and (max-width: 768px) {
  .third-list__item:nth-child(3n) {
    left: 0;
  }
}

.third-list__item:nth-child(3n+1) {
  left: -60px;
}

@media screen and (max-width: 768px) {
  .third-list__item:nth-child(3n+1) {
    left: 0;
  }
}

.third-list__ttl {
  display: inline-block;
  position: relative;
  margin-bottom: 20px;
  font-size: 16px;
  text-align: center;
}

@media screen and (max-width: 768px) {
  .third-list__ttl {
    margin-bottom: 10px;
  }
}

.third-list__ttl:before {
  position: absolute;
  top: 0px;
  left: -30px;
  width: 15px;
  height: 30px;
  background-image: url("../img/common/ico_ttl_left_01.png");
  background-repeat: no-repeat;
  background-position: left top;
  background-size: 100% auto;
  content: "";
}

.third-list__ttl:after {
  position: absolute;
  top: 2pxpx;
  right: -30px;
  width: 15px;
  height: 30px;
  background-image: url("../img/common/ico_ttl_right_01.png");
  background-repeat: no-repeat;
  background-position: left top;
  background-size: 100% auto;
  content: "";
}

.third-list__img-wrap {
  position: relative;
  z-index: 2;
  margin-bottom: 30px;
}

.third-list__img-wrap:before {
  right: -10px;
  bottom: -10px;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #fff;
  background-image: url("../img/common/bg_dot_02.png");
  background-position: right bottom;
  border: 1px solid #000;
  border-radius: 30px;
  box-sizing: border-box;
  content: "";
  border-radius: 10px;
}

.third-list__img {
  position: relative;
  z-index: 3;
  border: 1px solid #000;
  border-radius: 10px;
  overflow: hidden;
}

.third-list__txt {
  text-align: left;
}

.third-list__txt span {
  font-size: 13px;
}

@media screen and (max-width: 768px) {
  .page-section {
    padding-bottom: 45px;
  }
}

.page-section__ttl {
  position: relative;
  text-align: center;
  font-size: 20px;
  margin-bottom: 45px;
}

.page-section__ttl:before {
  position: absolute;
  top: -50px;
  left: 50%;
  width: 1px;
  height: 45px;
  background: #000;
  content: "";
}

@media screen and (max-width: 768px) {
  .page-section__ttl:before {
    height: 20px;
    top: -27px;
  }
}

@media screen and (max-width: 768px) {
  .page-section__ttl {
    margin-bottom: 25px;
    font-size: 19px;
  }
}

.page-section__ttl-inner {
  position: relative;
  display: inline-block;
}

.page-section__ttl-inner:before {
  position: absolute;
  top: -10px;
  left: -80px;
  width: 12px;
  height: 90px;
  background-image: url("../img/common/ico_ttl_left_02.png");
  background-repeat: no-repeat;
  background-position: left top;
  background-size: auto 100%;
  content: "";
}

@media screen and (max-width: 768px) {
  .page-section__ttl-inner:before {
    top: 0;
    height: 55px;
    left: -35px;
  }
}

.page-section__ttl-inner:after {
  position: absolute;
  top: -10px;
  right: -80px;
  width: 12px;
  height: 90px;
  background-image: url("../img/common/ico_ttl_right_02.png");
  background-repeat: no-repeat;
  background-position: left top;
  background-size: auto 100%;
  content: "";
}

@media screen and (max-width: 768px) {
  .page-section__ttl-inner:after {
    top: 0;
    height: 55px;
    right: -35px;
  }
}

.page-section__ttl-en {
  font-size: 10px;
  display: block;
}

@media screen and (max-width: 768px) {
  .page-section__ttl-en {
    font-size: 8px;
  }
}

.page-section__line-ttl {
  position: relative;
  font-size: 20px;
  text-align: center;
  margin-bottom: 30px;
  padding-bottom: 25px;
}

.page-section__line-ttl:before {
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 40px;
  margin-left: -20px;
  height: 3px;
  background-image: url("../img/common/line_02.png");
  background-repeat: no-repeat;
  background-position: left center;
  background-size: auto 3px;
  content: "";
}

.page-section__sub-ttl {
  font-size: 20px;
  margin-bottom: 20px;
}

@media screen and (max-width: 768px) {
  .page-section__sub-ttl {
    font-size: 18px;
  }
}

.page-section__sub-ttl--fig {
  width: 730px;
}

@media screen and (max-width: 768px) {
  .page-section__sub-ttl--fig {
    width: 100%;
  }
}

.page-section .half-list__item .page-section__sub-ttl {
  padding-top: 20px;
}

@media screen and (max-width: 768px) {
  .page-section .half-list__item .page-section__sub-ttl {
    padding-top: 0;
  }
}

.page-section__txt {
  margin-bottom: 45px;
}

@media screen and (max-width: 768px) {
  .page-section__txt {
    margin-bottom: 20px;
  }
}

.page-section__txt--center {
  margin-bottom: 45px;
  text-align: center;
}

.page-section__txt--fig {
  width: 730px;
}

@media screen and (max-width: 768px) {
  .page-section__txt--fig {
    width: 100%;
  }
}

.page-section__txt-wrap {
  margin-bottom: 80px;
  float: left;
}

@media screen and (max-width: 768px) {
  .page-section__txt-wrap {
    float: none;
    margin-bottom: 40px;
  }
}

.page-section__fig {
  float: right;
  width: 308px;
}

@media screen and (max-width: 768px) {
  .page-section__fig {
    margin-bottom: 70px;
    float: none;
    width: 100%;
  }
}

.page-section__img-wrap {
  position: relative;
  z-index: 2;
  margin-bottom: 45px;
}

.page-section__img-wrap:before {
  right: -10px;
  bottom: -10px;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #fff;
  background-image: url("../img/common/bg_dot_01.png");
  background-position: right bottom;
  border: 1px solid #000;
  border-radius: 30px;
  box-sizing: border-box;
  content: "";
  border-radius: 10px;
}

@media screen and (max-width: 768px) {
  .page-section__img-wrap:before {
    border-radius: 8px;
  }
}

.page-section__img-wrap--dot02:before {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #fff;
  background-image: url("../img/common/bg_dot_02.png");
  background-position: right bottom;
  border: 1px solid #000;
  border-radius: 30px;
  box-sizing: border-box;
  content: "";
  border-radius: 10px;
}

@media screen and (max-width: 768px) {
  .page-section__img-wrap--dot02:before {
    border-radius: 8px;
  }
}

.page-section__img {
  position: relative;
  z-index: 3;
  border: 1px solid #000;
  border-radius: 10px;
  overflow: hidden;
}

@media screen and (max-width: 768px) {
  .page-section__img {
    border-radius: 8px;
  }
}

.page-section--line {
  margin-top: 50px;
}

.page-section--line .container {
  padding-top: 160px;
  position: relative;
}

@media screen and (max-width: 768px) {
  .page-section--line .container {
    padding-top: 70px;
  }
}

.page-section--line .container:before {
  position: absolute;
  top: 0;
  left: -60px;
  width: 1200px;
  height: 1px;
  background: #000;
  content: "";
}

.page-section--menu .container {
  padding-top: 160px;
  position: relative;
}

@media screen and (max-width: 768px) {
  .page-section--menu .container {
    padding-top: 70px;
  }
}

.page-section--menu .container:before {
  position: absolute;
  top: 0;
  left: -60px;
  width: 1200px;
  height: 4px;
  background-image: url("../img/common/line_02.png");
  background-size: auto 4px;
  content: "";
}

.page-section--menu .container:after {
  display: block;
  width: 210px;
  background: #fff;
  position: absolute;
  top: -8px;
  left: 50%;
  margin-left: -105px;
  font-size: 12px;
  content: "RELAXATION MENU";
  text-align: center;
}

@media screen and (max-width: 768px) {
  .page-section--menu .container:after {
    font-size: 8px;
    width: 170px;
    margin-left: -85px;
  }
}

.page-section .page-top:before {
  top: -80px;
  left: inherit;
  right: 0;
}

.page-section--rent .page-top__txt-wrap {
  padding-top: 0;
}

.page-section--rent .page-top__img-wrap {
  margin-top: 80px;
}

@media screen and (max-width: 768px) {
  .page-section--rent .page-top__img-wrap {
    margin-top: 0;
  }
}

.calendar {
  margin-bottom: 130px;
}

@media screen and (max-width: 768px) {
  .calendar {
    margin-bottom: 60px;
  }
}

.calendar__ttl {
  position: relative;
  font-size: 18px;
  margin-bottom: 20px;
}

.calendar__ttl:before {
  position: absolute;
  top: 50%;
  left: -40px;
  width: 30px;
  height: 1px;
  background: #000;
  content: "";
}

@media screen and (max-width: 768px) {
  .calendar__ttl:before {
    display: none;
  }
}

.calendar__wrap {
  margin-bottom: 80px;
}

.calendar__wrap iframe {
  width: 100%;
  height: 600px;
}

@media screen and (max-width: 768px) {
  .calendar__wrap iframe {
    height: 500px;
  }
}

.calendar__btn {
  position: relative;
  display: block;
  text-align: center;
  line-height: 80px;
  height: 80px;
  width: 460px;
  background: #feecd2;
  border: 1px solid #000;
  border-radius: 6px;
  margin: 0 auto;
}

@media screen and (max-width: 768px) {
  .calendar__btn {
    width: 100%;
    max-width: 271px;
    line-height: 54px;
    height: 54px;
  }
}

.calendar__btn:before {
  position: absolute;
  top: 50%;
  left: -23px;
  width: 45px;
  height: 1px;
  background: #000;
  content: "";
  transition: all 300ms;
}

.calendar__btn:hover {
  text-decoration: none;
}

.calendar__btn:hover:before {
  left: -13px;
}

/* ------------------------------------------------
  blog
---------------------------------------------- */
.layout {
  padding-top: 80px;
}

@media screen and (max-width: 768px) {
  .layout {
    padding-top: 0;
  }
}

.layout .container:after {
  display: block;
  visibility: hidden;
  clear: both;
  height: 0;
  content: '';
}

.layout__main {
  width: 760px;
  float: left;
}

@media screen and (max-width: 768px) {
  .layout__main {
    width: 100%;
    float: none;
    margin-bottom: 75px;
  }
}

.layout__side {
  width: 240px;
  float: right;
}

@media screen and (max-width: 768px) {
  .layout__side {
    width: 100%;
    float: none;
  }
}

.blog-list {
  border-top: 1px solid #000;
  padding-top: 50px;
}

@media screen and (max-width: 768px) {
  .blog-list {
    padding-top: 0;
    border-top: none;
  }
}

.blog-list__item {
  position: relative;
  margin-bottom: 50px;
  border-bottom: 1px solid #000;
}

@media screen and (max-width: 768px) {
  .blog-list__item {
    margin-bottom: 25px;
    border-bottom: none;
  }
}

.blog-list__ttl {
  position: relative;
  font-size: 16px;
  margin-bottom: 55px;
}

@media screen and (max-width: 768px) {
  .blog-list__ttl {
    text-align: center;
    font-size: 14px;
    padding-bottom: 30px;
    margin-bottom: 15px;
  }
}

.blog-list__ttl:before {
  position: absolute;
  left: -35px;
  top: 50%;
  width: 20px;
  height: 1px;
  background: #000;
  content: "";
}

@media screen and (max-width: 768px) {
  .blog-list__ttl:before {
    width: 1px;
    height: 20px;
    left: 50%;
    bottom: 0;
    top: inherit;
  }
}

.blog-list__list:after {
  display: block;
  visibility: hidden;
  clear: both;
  height: 0;
  content: '';
}

.blog-list__link {
  display: block;
  opacity: 1;
  transition: opacity 250ms ease-out;
}

.blog-list__link:hover {
  opacity: .7;
}

@media screen and (max-width: 768px) {
  .blog-list__link:hover {
    opacity: 1;
  }
}

.blog-list__link:after {
  display: block;
  visibility: hidden;
  clear: both;
  height: 0;
  content: '';
}

.blog-list__link:hover {
  text-decoration: none;
}

.blog-list__img-wrap {
  position: relative;
  z-index: 2;
  margin-bottom: 50px;
  width: 300px;
  height: 200px;
  float: left;
}

@media screen and (max-width: 768px) {
  .blog-list__img-wrap {
    width: 100px;
    height: 74px;
    margin-bottom: 0;
  }
}

.blog-list__img-wrap:before {
  right: -10px;
  bottom: -10px;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #fff;
  background-image: url("../img/common/bg_dot_02.png");
  background-position: right bottom;
  border: 1px solid #000;
  border-radius: 30px;
  box-sizing: border-box;
  content: "";
  border-radius: 10px;
  content: "";
}

@media screen and (max-width: 768px) {
  .blog-list__img-wrap:before {
    display: none;
  }
}

.blog-list__img {
  position: relative;
  z-index: 3;
  width: 300px;
  height: 200px;
  overflow: hidden;
  border-radius: 10px;
  border: 1px solid #000;
  box-sizing: border-box;
}

@media screen and (max-width: 768px) {
  .blog-list__img {
    width: 100px;
    height: 74px;
    border-radius: 3px;
  }
}

.blog-list__img img {
  max-width: inherit;
  height: 100%;
}

.blog-list__item-ttl {
  position: relative;
  margin-left: 343px;
  font-size: 18px;
  padding-bottom: 20px;
  margin-bottom: 20px;
}

.blog-list__item-ttl:before {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 40px;
  height: 3px;
  background-image: url("../img/common/line_02.png");
  background-repeat: no-repeat;
  background-position: left center;
  background-size: auto 3px;
  content: "";
}

@media screen and (max-width: 768px) {
  .blog-list__item-ttl:before {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .blog-list__item-ttl {
    margin-bottom: 0;
    margin-left: 110px;
    padding-bottom: 0;
    font-size: 14px;
  }
}

.blog-list__txt {
  margin-bottom: 50px;
  font-size: 14px;
  margin-left: 343px;
}

@media screen and (max-width: 768px) {
  .blog-list__txt {
    display: none;
  }
}

.blog-list__meta {
  margin-left: 343px;
}

@media screen and (max-width: 768px) {
  .blog-list__meta {
    margin-left: 110px;
  }
}

.blog-list__date {
  font-size: 10px;
}

@media screen and (max-width: 768px) {
  .blog-list__date {
    line-height: 1.4;
    vertical-align: middle;
  }
}

.blog-list__category {
  position: absolute;
  top: 20px;
  left: -7px;
  display: block;
  width: 110px;
  height: 28px;
  line-height: 28px;
  background: #fff;
  font-size: 10px;
  z-index: 4;
  text-align: center;
  border-radius: 0 6px 6px 0;
  border: 1px solid #000;
  border-left: none;
  box-sizing: border-box;
}

@media screen and (max-width: 768px) {
  .blog-list__category {
    display: inline-block;
    position: relative;
    top: 0;
    left: 0;
    height: auto;
    line-height: 1.4;
    border: 1px solid #000;
    border-radius: 4px;
    width: 67px;
    vertical-align: middle;
    margin-left: 10px;
  }
}

.blog {
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
  margin-bottom: 90px;
  padding-top: 50px;
  padding-bottom: 80px;
}

@media screen and (max-width: 768px) {
  .blog {
    border-bottom: none;
    margin-bottom: 60px;
    padding-bottom: 0;
  }
}

.blog__top {
  position: relative;
  text-align: center;
  margin-bottom: 40px;
}

.blog__top:before {
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 40px;
  margin-left: -20px;
  height: 3px;
  background-image: url("../img/common/line_02.png");
  background-repeat: no-repeat;
  background-position: left center;
  background-size: auto 3px;
  content: "";
}

.blog__ttl {
  position: relative;
  display: inline;
  background: linear-gradient(transparent 70%, #feecd2 70%, #feecd2 100%, transparent 100%);
  font-size: 20px;
  text-align: center;
}

@media screen and (max-width: 768px) {
  .blog__ttl {
    font-size: 18px;
  }
}

.blog__date {
  display: block;
  padding-top: 20px;
  padding-bottom: 20px;
  font-size: 12px;
  text-align: center;
}

@media screen and (max-width: 768px) {
  .blog__date {
    padding-top: 10px;
  }
}

.blog__content p {
  font-size: 14px;
  margin-bottom: 1.5em;
}

.blog__category {
  font-size: 14px;
}

@media screen and (max-width: 768px) {
  .blog__category {
    font-size: 12px;
  }
}

.blog__category a {
  text-decoration: underline;
}

.blog__category a:hover {
  text-decoration: none;
}

.side {
  position: relative;
  padding: 20px 25px 30px;
  margin-bottom: 30px;
  border-top: 1px solid #000;
  font-size: 14px;
}

.side:before {
  position: absolute;
  top: -15px;
  left: 50%;
  width: 1px;
  height: 15px;
  background: #000;
  content: "";
}

.side__ttl-wrap {
  margin-bottom: 10px;
  text-align: center;
}

.side__ttl {
  display: inline;
  font-size: 16px;
  padding: 0 10px;
  background: linear-gradient(transparent 60%, #feecd2 60%, #feecd2 100%, transparent 100%);
}

.side__sub {
  font-size: 13px;
}

.side__name {
  font-size: 18px;
  margin-bottom: 20px;
}

.side__txt {
  font-size: 13px;
}

.pager {
  position: relative;
  text-align: center;
}

.pager:after {
  display: block;
  visibility: hidden;
  clear: both;
  height: 0;
  content: '';
}

.pager__item {
  display: inline-block;
  border-bottom: 1px solid #000;
  padding: 0 15px 5px;
  font-size: 16px;
}

@media screen and (max-width: 768px) {
  .pager__item {
    padding: 0 10px 5px;
  }
}

.pager__item--prev {
  position: absolute;
  top: 0;
  left: 0;
}

.pager__item--next {
  position: absolute;
  top: 0;
  right: 0;
}

.pager__item a:hover {
  text-decoration: none;
}

#wp-calendar {
  width: 100%;
  text-align: center;
  font-size: 12px;
}

#wp-calendar caption, #wp-calendar th, #wp-calendar td {
  text-align: center;
}

#wp-calendar td {
  padding: 5px 0;
}

.wp-pagenavi {
  margin-top: 60px;
  padding-top: 30px;
  text-align: center;
  font-size: 16px;
}

@media screen and (max-width: 768px) {
  .wp-pagenavi {
    margin-top: 40px;
    font-size: 14px;
  }
}

.wp-pagenavi .page,
.wp-pagenavi .current {
  display: inline-block;
  width: 54px;
  border-bottom: 1px solid #000;
  margin: 0 10px;
}

@media screen and (max-width: 768px) {
  .wp-pagenavi .page,
  .wp-pagenavi .current {
    width: 34px;
  }
}

.wp-pagenavi .current {
  position: relative;
}

.wp-pagenavi .current:before {
  position: absolute;
  top: -30px;
  left: 50%;
  width: 1px;
  height: 14px;
  background: #000;
  content: "";
}

@media screen and (max-width: 768px) {
  .wp-pagenavi .current:before {
    height: 9px;
    top: -14px;
  }
}

.wp-pagenavi .previouspostslink {
  display: inline-block;
  width: 94px;
  margin-right: 30px;
  border-bottom: 1px solid #000;
}

@media screen and (max-width: 768px) {
  .wp-pagenavi .previouspostslink {
    width: 54px;
  }
}

.wp-pagenavi .nextpostslink {
  display: inline-block;
  width: 94px;
  margin-left: 30px;
  border-bottom: 1px solid #000;
}

@media screen and (max-width: 768px) {
  .wp-pagenavi .nextpostslink {
    width: 54px;
  }
}

.wp-pagenavi a {
  text-decoration: none;
}

/* ------------------------------------------------
  form
---------------------------------------------- */
.reserve {
  position: relative;
  border: 3px solid #f2f2f3;
  margin-top: 110px;
  padding: 100px 100px;
}

@media screen and (max-width: 768px) {
  .reserve {
    margin-top: 45px;
    padding: 40px 20px 5px;
  }
}

.reserve:before {
  position: absolute;
  top: -31px;
  left: 50%;
  width: 1px;
  height: 63px;
  background: #000;
  content: "";
}

@media screen and (max-width: 768px) {
  .reserve:before {
    height: 46px;
    top: -23px;
  }
}

.reserve__ttl {
  margin-bottom: 50px;
  text-align: center;
  font-size: 22px;
}

@media screen and (max-width: 768px) {
  .reserve__ttl {
    margin-bottom: 30px;
    font-size: 18px;
  }
}

.reserve__ttl .font-en, .reserve__ttl .gnav__ttl, .reserve__ttl .gnav__en, .reserve__ttl .gnav__sns, .reserve__ttl .footer__facebook-ttl, .reserve__ttl .footer__phone, .reserve__ttl .reserve-sp__phone, .reserve__ttl .top-blog__date, .reserve__ttl .top-blog__more, .reserve__ttl .page-section--menu .container:after, .page-section--menu .reserve__ttl .container:after, .reserve__ttl .blog-list__date, .reserve__ttl .pager, .reserve__ttl #wp-calendar, .reserve__ttl .wp-pagenavi {
  display: block;
  font-size: 10px;
}

.reserve__note {
  margin-bottom: 65px;
  text-align: center;
  text-decoration: underline;
}

@media screen and (max-width: 768px) {
  .reserve__note {
    margin-bottom: 10px;
  }
}

.form {
  /*
  &__label {
    display: inline-block;
    position: relative;
    cursor: pointer;
    padding-left: 30px;
    font-size: 16px;
    z-index: 2;
    background: #fff;
    margin-right: 20px;
    margin-bottom: 10px;
    @include mq(sp) {
      line-height: 2.25;
      font-size: 15px;
    }
    &:before {
      position: absolute;
      content: "";
      z-index: 2;
    }
    &.checked {
      &:after {
        position: absolute;
        content: "";
        z-index: 3;
      }
    }
    &--checkbox {
      @include mq(sp) {
        margin-left: 0;
      }
      a {
        color: $color;
        text-decoration: underline;
        &:hover {
          text-decoration: none;
        }
      }
      &:before {
        top: 8px;
        left: 0;
        width: 20px;
        height: 20px;
        box-sizing: border-box;
        border: none;
        background: #f6f6f6;
        border-radius: 2px;
      }
      &.checked {
        &:after {
          top: 11px;
          left: 4px;
          width: 14px;
          height: 6px;
          border-left: 1px solid #000;
          border-bottom: 1px solid #000;
          transform: rotate(-45deg);
        }
      }
    }
  }
  */
}

.form .must {
  color: #ed1c24;
}

.form__table {
  margin-bottom: 40px;
}

@media screen and (max-width: 768px) {
  .form__table {
    display: block;
    margin-bottom: 20px;
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .form__table tbody {
    display: block;
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .form__table tr {
    display: block;
    width: 100%;
  }
}

.form__table th {
  width: 216px;
  vertical-align: top;
  padding: 35px 0;
}

@media screen and (max-width: 768px) {
  .form__table th {
    display: block;
    width: 100%;
    padding: 0 0;
    font-size: 15px;
    margin-bottom: 5px;
  }
}

.form__table td {
  vertical-align: top;
  padding: 35px 0;
}

@media screen and (max-width: 768px) {
  .form__table td {
    display: block;
    padding: 0;
    margin-bottom: 15px;
  }
}

.form__table .line th {
  border-bottom: 1px solid #f2f2f3;
}

@media screen and (max-width: 768px) {
  .form__table .line th {
    border-bottom: none;
  }
}

.form__table .line td {
  border-bottom: 1px solid #f2f2f3;
}

@media screen and (max-width: 768px) {
  .form__table .line td {
    padding-bottom: 35px;
  }
}

.form__check-list {
  text-align: left;
  width: 420px;
}

@media screen and (max-width: 768px) {
  .form__check-list {
    width: auto;
    max-width: 240px;
  }
}

.form__check-list label {
  display: inline-block;
}

@media screen and (max-width: 768px) {
  .form__check-list label {
    margin-left: 0;
  }
}

.form__check-list label a {
  color: #000;
  text-decoration: underline;
}

.form__check-list label a:hover {
  text-decoration: none;
}

.form__check-list label:before {
  top: 8px;
  left: 0;
  width: 20px;
  height: 20px;
  box-sizing: border-box;
  border: none;
  background: #f6f6f6;
  border-radius: 2px;
}

.form__check-list label.checked:after {
  top: 11px;
  left: 4px;
  width: 14px;
  height: 6px;
  border-left: 1px solid #000;
  border-bottom: 1px solid #000;
  transform: rotate(-45deg);
}

.form__check-list li {
  display: inline-block;
}

@media screen and (max-width: 768px) {
  .form__check-list .sp-block {
    display: block;
  }
}

.form__check-list .block {
  margin-bottom: 10px;
  display: block;
}

.form__list:after {
  display: block;
  visibility: hidden;
  clear: both;
  height: 0;
  content: '';
}

@media screen and (max-width: 768px) {
  .form__list {
    margin-top: 30px;
  }
}

.form__list dt {
  float: left;
  margin-bottom: 20px;
}

@media screen and (max-width: 768px) {
  .form__list dt {
    float: none;
    margin-bottom: 5px;
    font-size: 15px;
  }
}

.form__list dd {
  margin-left: 156px;
  margin-bottom: 20px;
}

@media screen and (max-width: 768px) {
  .form__list dd {
    margin-left: 0;
    font-size: 15px;
  }
}

.form__select {
  display: inline-block;
  border-radius: 4px;
  background-color: #f6f6f6;
  background-image: url("../img/common/ico_select_01.png");
  background-repeat: no-repeat;
  background-position: right center;
  background-size: 20px auto;
  border: none;
  width: 120px;
  height: 45px;
  box-sizing: border-box;
  padding: 0 15px;
  line-height: 45px;
  margin-right: 10px;
  font-size: 15px;
  cursor: pointer;
  -webkit-appearance: none;
}

@media screen and (max-width: 768px) {
  .form__select {
    font-size: 16px;
  }
}

.form label {
  position: relative;
  cursor: pointer;
  padding-left: 30px;
  font-size: 16px;
  z-index: 2;
  background: #fff;
  margin-right: 20px;
  margin-bottom: 10px !important;
}

.form label:before {
  position: absolute;
  content: "";
  z-index: 2;
}

.form label.checked:after {
  position: absolute;
  content: "";
  z-index: 3;
}

.form__checkbox {
  position: absolute;
  top: 0;
  left: 10px;
  z-index: -1;
  opacity: 0;
}

.form__item {
  display: inline-block;
  margin-right: 20px;
}

@media screen and (max-width: 768px) {
  .form__item {
    margin-right: 2px;
  }
  .form__item .form__select {
    margin-right: 5px;
    padding: 0 0 0 8px;
    width: 54px;
  }
}

.form__input {
  display: block;
  width: 100%;
  font-size: 15px;
  border: none;
  box-shadow: none;
  padding: 13px 15px;
  letter-spacing: 0.06em;
  border-radius: 4px;
  background: #f6f6f6;
  -webkit-appearance: none;
  box-sizing: border-box;
}

.form__input::-webkit-input-placeholder {
  color: #ddd;
  -webkit-font-smoothing: antialiased;
}

.form__input:-moz-placeholder {
  color: #ddd;
  -moz-osx-font-smoothing: grayscale;
}

.form__input::-moz-placeholder {
  color: #ddd;
  -moz-osx-font-smoothing: grayscale;
  opacity: 1;
}

.form__input:-ms-input-placeholder {
  color: #ddd;
}

@media screen and (max-width: 768px) {
  .form__input {
    font-size: 16px;
  }
}

.form__textarea {
  display: block;
  width: 100%;
  height: 180px;
  border: none;
  -webkit-appearance: none;
  box-shadow: none;
  border-radius: 0;
  resize: none;
  background: #f6f6f6;
  font-size: 16px;
  padding: 12px 15px;
  box-sizing: border-box;
  border-radius: 4px;
}

@media screen and (max-width: 768px) {
  .form__textarea {
    margin-bottom: 25px;
  }
}

.form__btn-list {
  text-align: center;
}

@media screen and (max-width: 768px) {
  .form__btn-list {
    display: flex;
    flex-direction: column-reverse;
  }
}

.form__btn-list li {
  display: inline-block;
  margin: 0 20px;
}

@media screen and (max-width: 768px) {
  .form__btn-list li {
    display: block;
    margin: 0 0 20px;
  }
}

.form__reset-btn {
  position: relative;
  width: 280px;
  height: 64px;
  line-height: 64px;
  font-size: 16px;
  border: none;
  background: #b3b3b3;
  color: #fff;
  border-radius: 0;
  box-shadow: none;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .form__reset-btn {
    width: 100%;
  }
}

.form__reset-btn:before {
  position: absolute;
  top: 50%;
  right: 20px;
  width: 40px;
  height: 1px;
  background: #fff;
  content: "";
  transition: all 300ms;
}

.form__reset-btn:hover:before {
  width: 60px;
  right: 0;
}

.form__back-btn {
  position: relative;
  width: 280px;
  height: 64px;
  line-height: 64px;
  font-size: 16px;
  border: none;
  background: #b3b3b3;
  color: #fff;
  border-radius: 0;
  box-shadow: none;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .form__back-btn {
    width: 100%;
  }
}

.form__back-btn:before {
  position: absolute;
  top: 50%;
  left: 20px;
  width: 40px;
  height: 1px;
  background: #fff;
  content: "";
  transition: all 300ms;
}

.form__back-btn:hover:before {
  width: 60px;
  left: 0;
}

.form__submit-btn-wrap {
  position: relative;
}

.form__submit-btn-wrap:before {
  position: absolute;
  top: 50%;
  right: 20px;
  width: 40px;
  height: 1px;
  background: #fff;
  content: "";
  transition: all 300ms;
}

.form__submit-btn-wrap:hover:before {
  width: 60px;
  right: 0;
}

.form__submit-btn {
  width: 280px;
  height: 64px;
  line-height: 64px;
  font-size: 16px;
  border: none;
  background: #000;
  color: #fff;
  border-radius: 0;
  box-shadow: none;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .form__submit-btn {
    width: 100%;
  }
}

.form__check-list .block_item .block_item_after {
  display: none;
}

.form__check-list .block_item.show .block_item_after {
  display: inline;
}

.mw_wp_form .horizontal-item + .horizontal-item {
  margin-left: 0 !important;
}

.mw_wp_form_confirm .form__list dt,
.mw_wp_form_confirm .form__reset-btn {
  display: none !important;
}

.mw_wp_form_confirm .form__list dd {
  margin-left: 0;
  margin-bottom: 0;
}

.mw_wp_form .error {
  font-size: 12px !important;
}
