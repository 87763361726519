/* ------------------------------------------------
  TOP
---------------------------------------------- */
.mv {
  position: relative;
  margin-bottom: 100px;
  padding: 0 75px 0;
  height: calc(100vh - 140px);
  max-height: 1600px;
  @include mq(sp) {
    margin-bottom: 50px;
    padding: 0 25px 0;
    height: auto;
  }
  &:before {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 12%;
    max-width: 168px;
    height: 240px;
    transform: translate(-50%,-50%);
    background-image: url("../img/common/logo_01.png");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 100% auto;
    content: "";
    z-index: 80;
    @include mq(sp) {
      width: 140px;
      height: 200px;
    }
  }
  &__wrap {
    position: relative;
    z-index: 1;
    &:before {
      @include bg-dot;
      right: -40px;
      bottom: -39px;
      width: 50%;
      @include mq(sp) {
        right: -2%;
        bottom: -2%;
        @include bg-dot;
        background-size: auto;
        background-repeat: repeat;
        border-radius: 7px;
      }
    }
    &:after {
      position: absolute;
      bottom: -39px;
      left: 40px;
      background-image: url("../img/top/bg_mv_bottom.png");
      background-repeat: no-repeat;
      background-position: left bottom;
      background-size: auto 100%;
      content: "";
      width: calc(100% - 40px);
      height: 40px;
      z-index: 10;
      @include mq(sp) {
        display: none;
      }
    }
    
  }
  &__list {
    z-index: 20;
    border-radius: 30px;
    background: #fff;;
  }
  &__item {
    @include bg-img;
    border-radius: 30px;
    border: 1px solid #000;
    box-sizing: border-box;
    height: calc(100vh - 180px);
    max-height: 1600px;
    overflow: hidden;
    @include mq(sp) {
      border-radius: 7px;
      height: auto;
    }
  }
  &__img {
    display: none !important;
    @include mq(sp) {
      display: block !important;
    }
  }
  &__txt {
    position: absolute;
    padding-bottom: 5px;
    padding-left: 5px;
    font-size: 17px;
    color: #fff;
    background-image: url("../img/top/line_mv_01.png");
    background-size: 278px auto;
    background-repeat: no-repeat;
    background-position: left bottom;
    &--01 {
      top: 43%;
      left: 12%;
    }
    &--02 {
      top: 45%;
      right: 17%;
    }
  }
  &__en {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 33px;
    width: 11px;
  }
  &__scroll {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -40px;
    width: 77px;
    height: 80px;
    text-align: center;
    font-size: 10px;
    z-index: 3;
    overflow: hidden;
    &:after {
      animation: scrollbar 1.8s infinite linear;
      background: #000;
      content: "";
      height: 90px;
      left: 50%;
      position: absolute;
      top: 0;
      width: 1px;
      z-index: -1;
    }
  }
  &__scroll-txt {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    display: block;
    width: 77px;
    height: 24px;
    background: #fff;
    z-index: 3;
  }
}
@keyframes scrollbar {
  0% {
    transform: translateY(-100px);
  }
  100% {
    transform: translateY(100px);
  }
}
.top-container {
  width: 1200px;
  margin: 0 auto;
  @include mq(sp) {
    width: 100%;
    padding: 0 25px;
    box-sizing: border-box;
  }
}
.top-slide {
  position: relative;
  margin-bottom: 77px;
  padding-bottom: 85px;
  @include mq(sp) {
    margin-bottom: 65px;
  }
  &:before {
    position: absolute;
    top: 0;
    left: 50%;
    height: 159px;
    width: 206px;
    margin-left: -300px;
    background-image: url("../img/common/deco_01.png");
    background-position: left top;
    background-repeat: no-repeat;
    background-size: 206px auto;
    content: "";
    z-index: 70;
    @include mq(sp) {
      display: none;
    }
  }
  .top-container {
    padding-top: 65px;
    position: relative;
    @include clearfix;
    @include mq(sp) {
      padding-top: 0;
    }
    &:before {
      position: absolute;
      bottom: -70px;
      left: -250px;
      height: 159px;
      width: 206px;
      background-image: url("../img/common/deco_01.png");
      background-position: left top;
      background-repeat: no-repeat;
      background-size: 206px auto;
      content: "";
      z-index: 70;
      @include mq(sp) {
        display: none;
      }
    }
  }
  &__txt-wrap {
    padding-top: 20px;
    float: left;
    width: 300px;
    @include mq(sp) {
      float: none;
      width: 100%;
      padding-top: 0;
      margin-bottom: 30px;
    }
  }
  &__ttl {
    margin-bottom: 10px;
    font-size: 20px;
    @include mq(sp) {
      font-size: 18px;
    }
  }
  &__en {
    position: relative;
    padding-bottom: 25px;
    margin-bottom: 15px;
    font-size: 10px;
    line-height: 2.5;
    &:before {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 40px;
      height: 3px;
      background-image: url("../img/common/line_02.png");
      background-repeat: no-repeat;
      background-position: left center;
      background-size: auto 3px;
      content: "";
    }
  }
  &__item {
    position: relative;
    border-radius: 30px;
    overflow: hidden;
    background: #fff;
    border: 1px solid #000;
    box-sizing: border-box;
    z-index: 30;
    @include mq(sp) {
      border-radius: 8px;
    }
  }
  &__wrap {
    position: relative;
    float: right;
    width: 900px;
    height: 460px;
    right: -50px;
    @include mq(sp) {
      float: none;
      right: 0;
      width: 100%;
      height: auto;
    }
    &:before {
      @include bg-dot;
      bottom: -30px;
      right: -30px;
      @include mq(sp) {
        right: -10px;
        bottom: -10px;
        border-radius: 8px;
      }
    }
  }
  &__list {
    border-radius: 30px;
    background: #fff;
  }
  .slick-dots {
    position: absolute;
    right: 166px;
    width: 260px;
    height: 38px;
    bottom: -48px;
    background: #fff;
    text-align: center;
    @include mq(sp) {
      right: inherit;
      left: 50%;
      margin-left: -130px;
    }
    li {
      display: inline-block;
      margin: 0 8px;
      padding-top: 14px;
    }
    button {
      cursor: pointer;
      text-indent: -9999px;
      overflow: hidden;
      position: relative;
      -webkit-appearance: none;
      width: 10px;
      height: 10px;
      display: block;
      border: none;
      background: #fff;
      &:before {
        position: absolute;
        top: 0;
        left: 2px;
        background-image: url("../img/common/ico_dot_01.png");
        background-repeat: no-repeat;
        background-position: left top;
        background-size: 10px auto;
        text-indent: 0px;
        width: 10px;
        height: 10px;
        content: "";
      }
    }
    .slick-active {
      button {
        &:before {
          background-image: url("../img/common/ico_dot_active_01.png");
        }
      }
    }
  }
  .slick-arrow {
    position: absolute;
    bottom: -35px;
    z-index: 60;
    cursor: pointer;
  }
  .prev-arrow {
    width: 31px;
    right: 376px;
    @include mq(sp) {
      right: inherit;
      left: 50%;
      margin-left: -100px;
    }
  }
  .next-arrow {
    width: 31px;
    right: 180px;
    @include mq(sp) {
      right: inherit;
      left: 50%;
      margin-left: 70px;
    }
  }
}
.top-service {
  margin-bottom: 78px;
  @include mq(sp) {
    margin-bottom: 15px;
  }
  .top-container {
    @include clearfix;
  }
  &__ttl {
    position: relative;
    font-size: 16px;
    float: left;
    @include mq(sp) {
      float: none;
      margin-bottom: 50px;
      font-size: 14px;
      text-align: center;
    }
    &:before {
      position: absolute;
      left: -35px;
      top: 50%;
      width: 20px;
      height: 1px;
      background: #000;
      content: "";
      @include mq(sp) {
        width: 1px;
        height: 20px;
        left: 50%;
        top: 35px;
      }
    }
  }
  &__catch {
    position: relative;
    top: -15px;
    width: 667px;
    float: right;
    right: -112px;
    margin-bottom: 50px;
  }
  &__menu {
    @include clearfix;
    clear: both;
    padding-bottom: 10px;
    background-image: url("../img/top/bg_menu_01.png");
    background-size: 1193px auto;
    background-position: center top;
    background-repeat: no-repeat;
    @include mq(sp) {
      background: none;
    }
  }
  &__item {
    position: relative;
    width: 550px;
    @include mq(sp) {
      width: 100%;
      margin-bottom: 32px !important;
    }
    &:before {
      right: -20px;
      bottom: -20px;
      @include bg-dot;
      z-index: 2;
      border-radius: 32px;
      @include mq(sp) {
        right: -10px;
        bottom: -10px;
        border-radius: 8px;
      }
    }
    &--01 {
      float: left;
      margin-bottom: 120px;
      height: 480px;
      @include mq(sp) {
        float: none;
        height: auto;
      }
      .top-service__item-ttl {
        width: 260px;
        height: 127px;
        padding-top: 45px;
        background-image: url("../img/top/bg_ttl_service_01_pc.png");
        @include mq(sp) {
          width: 220px;
          height: 89px;
          padding-top: 28px;
          background-image: url("../img/top/bg_ttl_service_01_sp.png");
        }
      }
    }
    &--02 {
      margin-top: 80px;
      float: right;
      margin-bottom: 90px;
      height: 480px;
      @include mq(sp) {
        float: none;
        height: auto;
        margin-top: 0;
      }
      .top-service__item-ttl {
        width: 260px;
        height: 127px;
        padding-top: 45px;
        background-image: url("../img/top/bg_ttl_service_02_pc.png");
        @include mq(sp) {
          width: 220px;
          height: 89px;
          padding-top: 28px;
          background-image: url("../img/top/bg_ttl_service_02_sp.png");
        }
      }
    }
    &--03 {
      clear: both;
      float: right;
      width: 550px;
      height: 200px;
      @include mq(sp) {
        float: none;
        width: 100%;
        height: auto;
      }
      .top-service__img-wrap {
        border: none;
        border-left: 1px solid #000;
        border-radius: 0;
      }
      .top-service__link {
        display: table;
        width: 550px;
        border: 1px solid #000;
        border-radius: 30px;
        background: #fff;
        overflow: hidden;
        @include mq(sp) {
          width: 100%;
          border-radius: 8px;
          overflow: hidden;
        }
      }
      .top-service__item-ttl {
        display: table-cell;
        position: relative;
        top: 0;
        left: 0;
        text-align: left;
        transform: translate(0,0);
        float: none;
        padding-left: 48px;
        vertical-align: middle;
        @include mq(sp) {
          font-size: 16px;
          padding-left: 30px;
        }
      }
      .top-service__img-wrap {
        display: table-cell;
        width: 275px;
        @include mq(sp) {
          width: 40%;
        }
      }
    }
    &--04 {
      float: left;
      margin-top: -50px;
      width: 550px;
      height: 200px;
      @include mq(sp) {
        float: none;
        width: 100%;
        height: auto;
        margin-top: 0;
      }
      .top-service__img-wrap {
        border: none;
        border-left: 1px solid #000;
        border-radius: 0;
      }
      .top-service__link {
        display: table;
        border: 1px solid #000;
        border-radius: 30px;
        background: #fff;
        width: 550px;
        overflow: hidden;
        @include mq(sp) {
          border-radius: 8px;
          width: 100%;
        }
      }
      .top-service__item-ttl {
        display: table-cell;
        position: relative;
        top: 0;
        left: 0;
        text-align: left;
        transform: translate(0,0);
        float: none;
        padding-left: 48px;
        vertical-align: middle;
        @include mq(sp) {
          font-size: 16px;
          padding-left: 30px;
        }
      }
      .top-service__img-wrap {
        display: table-cell;
        width: 275px;
        @include mq(sp) {
          width: 40%;
        }
      }
    }
  }
  &__img-wrap {
    position: relative;
    z-index: 3;
    opacity: 1;
    transition: opacity 300ms;
    border: 1px solid #000;
    border-radius: 32px 32px 0 0;
    overflow: hidden;
    @include mq(sp) {
      border-radius: 8px 8px 0 0;
      overflow: hidden;
    }
  }
  &__img {
    position: relative;
  }
  &__item-ttl {
    position: absolute;
    font-size: 18px;
    text-align: center;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    line-height: 1.6;
    z-index: 4;
    box-sizing: border-box;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100% auto;
    @include mq(sp) {
      font-size: 17px;
    }
    .font-en {
      font-size: 8px;
      display: block;
    }
  }
  &__txt {
    position: relative;
    z-index: 4;
    padding: 30px 40px 0;
    border: 1px solid #000;
    border-radius: 0 0 30px 30px;
    box-sizing: border-box;
    height: 152px;
    border-top: none;
    background: #fff;
    @include mq(sp) {
      padding: 20px 25px;
      height: auto;
      border-radius: 0 0 10px 10px;
    }
  }
  &__link {
    position: relative;
    display: block;
    z-index: 8;
    @include clearfix;
    background: #fff;
    border-radius: 30px;
    &:hover {
      text-decoration: none;
      .top-service__img-wrap {
        opacity: 0.7;
      }
    }
  }
}
.top-gallery {
  margin-bottom: 156px;
  padding: 0 60px;
  @include mq(pc) {
    padding: 0 25px;
  }
  @include mq(sp) {
    margin-bottom: 85px;
  }
  &__ttl {
    position: relative;
    margin-bottom: 30px;
    padding-left: 10px;
    @include mq(sp) {
      margin-bottom: 0;
      padding-bottom: 5px;
      padding-left: 15px;
      font-size: 10px;
    }
    &:before {
      position: absolute;
      top: -30px;
      left: -30px;
      width: 4px;
      height: 112px;
      background-image: url("../img/common/line_01.png");
      background-repeat: no-repeat;
      background-size: 4px auto;
      background-position: left top;
      content: "";
      @include mq(sp) {
        left: 0;
        height: 30px;
        top: -5px;
      }
    }
  }
  &__item {
    position: relative;
    width: 100%;
    border-radius: 20px;
    @include bg-img;
    @include mq(sp) {
      width: 100%;
      left: 0;
      border-radius: 8px;
    }
    &:before {
      right: -30px;
      bottom: -30px;
      @include bg-dot;
      z-index: -1;
      border-radius: 20px;
      @include mq(sp) {
        right: -10px;
        bottom: -10px;
        border-radius: 8px;
      }
    }
  }
  &__img {
    position: relative;
    z-index: 3;
    border: 1px solid #000;
    box-sizing: border-box;
    border-radius: 20px;
    overflow: hidden;
    @include mq(sp) {
      border-radius: 8px;
    }
  }
}
.top-blog {
  @include mq(sp) {
    margin-bottom: 45px;
  }
  .top-container {
    position: relative;
    @include mq(pc) {
      width: 1080px;
      margin: 0 auto;
    }
    @include mq(sp) {
      width: 100%;
    }
  }
  &__ttl {
    position: relative;
    font-size: 16px;
    margin-bottom: 55px;
    @include mq(sp) {
      text-align: center;
      font-size: 14px;
      padding-bottom: 30px;
      margin-bottom: 15px;
    }
    &:before {
      position: absolute;
      left: -35px;
      top: 50%;
      width: 20px;
      height: 1px;
      background: #000;
      content: "";
      @include mq(sp) {
        width: 1px;
        height: 20px;
        left: 50%;
        bottom: 0;
        top: inherit;
      }
    }
  }
  &__list {
    @include clearfix;
  }
  &__link {
    display: block;
    @include fadeAnime;
    @include clearfix;
    &:hover {
      text-decoration: none;
    }
  }
  &__item {
    position: relative;
    float: left;
    width: 332px;
    margin-left: 102px;
    @include mq(pc) {
      margin-left: 42px;
    }
    @include mq(sp) {
      float: none;
      width: 100%;
      margin-left: 0;
      margin-bottom: 24px;
    }
    &:first-child {
      margin-left: 0;
    }
  }
  &__img-wrap {
    position: relative;
    z-index: 2;
    margin-bottom: 30px;
    width: 332px;
    height: 200px;

    @include mq(sp) {
      width: 100px;
      height: auto;
      border-radius: 3px;
      overflow: hidden;
      float: left;
      margin-bottom: 0;
    }
    &:before {
      right: -10px;
      bottom: -10px;
      @include bg-dot-02;
      border-radius: 10px;
      z-index: 2;
      content: "";
      @include mq(sp) {
        display: none;
      }
    }
  }
  &__img {
    position: relative;
    z-index: 3;
    border: 1px solid #000;
    border-radius: 10px;
    overflow: hidden;
    @include mq(sp) {
      border-radius: 3px;
    }
  }
  &__item-ttl {
    height: 60px;
    @include mq(sp) {
      padding-top: 24px;
      margin-left: 112px;
      height: auto;
    }
  }
  &__meta {
    @include mq(sp) {
      position: absolute;
      top: 0;
      left: 112px;
    }
  }
  &__date {
    font-size: 10px;
    @extend .font-en;
    @include mq(sp) {
      line-height: 1.4;
      vertical-align: middle;
    }
  }
  &__category {
    position: absolute;
    top: 20px;
    left: -7px;
    display: block;
    width: 110px;
    height: 28px;
    line-height: 28px;
    background: #fff;
    font-size: 10px;
    z-index: 4;
    text-align: center;
    border-radius: 0 6px 6px 0;
    border: 1px solid #000;
    border-left: none;
    box-sizing: border-box;
    @include mq(sp) {
      display: inline-block;
      position: relative;
      top: 0;
      left: 0;
      height: auto;
      line-height: 1.4;
      border: 1px solid #000;
      border-radius: 4px;
      width: 67px;
      vertical-align: middle;
    }
  }
  &__more {
    display: inline-block;
    padding: 0 6px 3px 6px;
    position: absolute;
    top: 0;
    right: 0;
    font-size: 12px;
    @extend .font-en;
    background-image: url("../img/common/line_02.png");
    background-size: auto 3px;
    background-position: -10px bottom;
    background-repeat: no-repeat;
    transition: background 300ms;
    @include mq(sp) {
      position: relative;
      text-align: center;
      display: block;
      width: 90px;
      margin: 0 auto;
      padding: 0 2px 3px 2px;
    }
    &:hover {
      text-decoration: none;
      background-position: 0 bottom;
      @include mq(sp) {
        background-position: -10px bottom;
      }
    }
  }
}