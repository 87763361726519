/* ------------------------------------------------
  page
---------------------------------------------- */
.page-ttl {
  padding-bottom: 100px;
  text-align: center;
  font-size: 22px;
  background-image: url("../img/common/bg_page_ttl_01.png");
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: 358px auto;
  @include mq(sp) {
    margin-bottom: 45px;
    padding-top: 30px;
    font-size: 20px;
    padding-bottom: 50px;
    background-size: 200px auto;
  }
  &-en {
    display: block;
    font-size: 10px;
    line-height: 1.4;
  }
}
.page-top {
  position: relative;
  margin-bottom: 142px;
  @include mq(sp) {
    margin-bottom: 60px;
  }
  &:before {
    position: absolute;
    top: 0;
    left: 50%;
    margin-left: 324px;
    width: 206px;
    height: 159px;
    background-image: url("../img/common/deco_01.png");
    background-repeat: no-repeat;
    background-size: 100% auto;
    content: "";
    z-index: 6;
    @include mq(sp) {
      display: none;
    }
  }
  .container {
    position: relative;
    padding-top: 80px;
    @include clearfix;
    @include mq(sp) {
      padding-top: 0;
    }
    &:before {
      position: absolute;
      top: 410px;
      left: -308px;
      height: 159px;
      width: 206px;
      background-image: url(../img/common/deco_01.png);
      background-position: left top;
      background-repeat: no-repeat;
      background-size: 206px auto;
      content: "";
      z-index: 70;
      @include mq(sp) {
        display: none;
      }
    }
  }
  &__txt-wrap {
    float: left;
    width: 390px;
    @include mq(sp) {
      float: none;
      width: 100%;
      margin-bottom: 30px;
    }
  }
  &__ttl {
    padding-top: 20px;
    font-size: 20px;
    position: relative;
    padding-bottom: 25px;
    margin-bottom: 30px;
    @include mq(sp) {
      font-size: 18px;
      padding-top: 0;
    }
    &:before {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 40px;
      height: 3px;
      background-image: url("../img/common/line_02.png");
      background-repeat: no-repeat;
      background-position: left center;
      background-size: auto 3px;
      content: "";
    }
    &--center {
      text-align: center;
      @include mq(sp) {
        text-align: left;
      }
      &:before {
        left: 50%;
        margin-left: -20px;
        @include mq(sp) {
          left: 0;
          margin-left: 0;
        }
      }
    }
  }
  &__txt {
    margin-bottom: 90px;
    @include mq(sp) {
      margin-bottom: 40px;
    }
    &--center {
      text-align: center;
      @include mq(sp) {
        text-align: left;
      }
    }
    &--mt {
      margin-top: 100px;
      font-size: 20px;
      @include mq(sp) {
        margin-top: 0;
        font-size: 18px;
      }
    }
  }
  &__img-wrap {
    position: relative;
    width: 750px;
    left: 30px;
    margin-left: 390px;    
    box-sizing: border-box;
    img {
      border: 1px solid #000;
      border-radius: 20px;
      @include mq(sp) {
        border-radius: 8px;
      }
    }
    @include mq(sp) {
      margin-left: 0;
      left: 0;
      width: 100%;
    }
    &:before {
      right: -30px;
      bottom: -30px;
      @include bg-dot;
      border-radius: 20px;
      @include mq(sp) {
        @include bg-dot-02;
        right: -10px;
        bottom: -10px;
        border-radius: 8px;
      }
    }
    &--center {
      width: auto;
      left: 0;
      margin-left: 0;
    }
  }
  &__img {
    position: relative;
    z-index: 3;
  }
  &__contact {
    text-align: center;
    margin-top: -20px;
  }
  &__phone {
    display: inline-block;
    padding: 0 20px;
    margin-bottom: 30px;
    font-size: 25px;
    background-image: url(../img/common/line_02.png);
    background-size: auto 5px;
    background-position: left bottom;
    background-repeat: no-repeat;
    @include mq(sp) {
      background-size: auto 3px;
    }
  }
  &__info {
    li {
      display: inline-block;
      margin: 0 40px;
      @include mq(sp) {
        display: block;
        margin: 0;
      }
    }
  }
  &--rent {
    .page-top__txt-wrap {
      padding-top: 70px;
      width: 510px;
      @include mq(sp) {
        width: 100%;
        padding-top: 0;
      }
    }
    .page-top__img-wrap {
      margin-top: 20px;
      width: 570px;
      margin-left: 570px;
      @include mq(sp) {
        width: 100%;
        margin-left: 0;
        margin-bottom: 80px;
      }
    }
  }
  &--reserve {
    margin-bottom: 60px;
    &:before {
      display: none;
    }
  }
  &--archive {
    margin-bottom: 0;
    .container {
      padding: 0;
      @include mq(sp) {
        padding: 0 25px;
      }
    }
    .page-top__ttl {
      margin-bottom: 0;
      @include mq(sp) {
        margin-bottom: 20px;
      }
    }
    
  }
  &--school {
    &:before {
      display: none;
    }
  }
}
.half-list {
  clear: both;
  @include clearfix;
  margin-bottom: 10px;
  padding: 20px 30px 0;
  @include mq(sp) {
    padding: 0;
    margin-bottom: 45px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  &__item {
    position: relative;
    width: 50%;
    float: left;
    margin-bottom: 70px;
    @include mq(sp) {
      width: 100%;
      float: none;
      margin-bottom: 40px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    &:nth-child(odd) {
      left: -30px;
      @include mq(sp) {
        left: 0;
      }
    }
    &:nth-child(even) {
      left: 30px;
      @include mq(sp) {
        left: 0;
      }
    }
    &--sp-mb {
      @include mq(sp) {
        margin-bottom: 110px;
      }
    }
    .page-section__img-wrap {
      margin-bottom: 0;
    }
  }
  &__ttl-wrap {
    position: absolute;
    top: -21px;
    left: -20px;
    min-width: 260px;
    @include mq(sp) {
      left: 50%;
      margin-left: -130px;
    }
    &:before {
      @include bg-dot;
      right: -5px;
      bottom: -5px;
      z-index: 2;
      border-radius: 5px;
    }
    &--sp-br {
      @include mq(sp) {
        top: -44px;
        .half-list__ttl {
          padding: 6px 30px;
        }
        +.half-list__container {
          padding: 45px 25px 10px;
        }
      }
    }
    &--sp-br-2 {
      @include mq(sp) {
        top: -82px;
        .half-list__ttl {
          padding: 7px 30px;
        }
        +.half-list__container {
          padding: 45px 25px 10px;
        }
      }
    }
  }
  &__ttl {
    position: relative;
    background: #feecd2;
    text-align: center;
    border: 1px solid #000;
    border-radius: 5px;
    box-sizing: border-box;
    padding: 5px 30px;
    z-index: 3;
    white-space: nowrap;
    span {
      font-size: 12px;
    }
  }
  &__sub-ttl {
    font-size: 18px;
    +.data-list {
      margin-top: 20px;
      @include mq(sp) {
        margin-top: 10px;
      }
    }
  }
  &__txt {
    margin-bottom: 20px;
    font-size: 16px;
    @include mq(sp) {
      font-size: 14px;
      margin-bottom: 10px;
    }
  }
  &__container {
    position: relative;
    border: 1px solid #000;
    border-radius: 10px;
    padding: 40px 40px 20px;
    box-sizing: border-box;
    @include mq(sp) {
      padding: 35px 25px 20px;
      border-radius: 7px;
    }
    &:before {
      position: absolute;
      top: -5px;
      left: 240px;
      width: 10px;
      height: 10px;
      content: "";
      background: #fff;
      @include mq(sp) {
        display: none;
      }
    }
    &:after {
      position: absolute;
      top: 20px;
      left: -5px;
      width: 10px;
      height: 10px;
      content: "";
      background: #fff;
      @include mq(sp) {
        display: none;
      }
    }
  }
  &__only-txt {
    margin-top: 140px;
    @include mq(sp) {
      margin-top: 0;
    }
  }
  &--txt-data {
    padding: 80px 30px 0;
    @include mq(sp) {
      padding: 0;
    }
    .page-section__sub-ttl {
      margin-top: -40px;
      @include mq(sp) {
        margin-top: 0;
      }
    }
  }
  +.half-list {
    @include mq(sp) {
      margin-top: 60px;
    }
  }
}
.third-list {
  @include clearfix;
  margin-bottom: 135px;
  padding: 0 60px;
  @include mq(sp) {
    margin-bottom: 45px;
    padding: 0;
  }
  &__item {
    position: relative;
    float: left;
    width: 33.3%;
    text-align: center;
    @include mq(sp) {
      width: 100%;
      margin-bottom: 50px;
    }
    &:nth-child(3n) {
      left: 60px;
      @include mq(sp) {
        left: 0;
      }
    }
    &:nth-child(3n+1) {
      left: -60px;
      @include mq(sp) {
        left: 0;
      }
    }
  }
  &__ttl {
    display: inline-block;
    position: relative;
    margin-bottom: 20px;
    font-size: 16px;
    text-align: center;
    @include mq(sp) {
      margin-bottom: 10px;
    }
    &:before {
      position: absolute;
      top: 0px;
      left: -30px;
      width: 15px;
      height: 30px;
      background-image: url("../img/common/ico_ttl_left_01.png");
      background-repeat: no-repeat;
      background-position: left top;
      background-size: 100% auto;
      content: "";
    }
    &:after {
      position: absolute;
      top: 2pxpx;
      right: -30px;
      width: 15px;
      height: 30px;
      background-image: url("../img/common/ico_ttl_right_01.png");
      background-repeat: no-repeat;
      background-position: left top;
      background-size: 100% auto;
      content: "";
    }
  }
  &__img-wrap {
    position: relative;
    z-index: 2;
    margin-bottom: 30px;
    &:before {
      right: -10px;
      bottom: -10px;
      @include bg-dot-02;
      border-radius: 10px;
    }
  }
  &__img {
    position: relative;
    z-index: 3;
    border: 1px solid #000;
    border-radius: 10px;
    overflow: hidden;
  }
  &__txt {
    text-align: left;
    span {
      font-size: 13px;
    }
  }
}
.page-section {
  @include mq(sp) {
    padding-bottom: 45px;
  }
  &__ttl {
    position: relative;
    text-align: center;
    font-size: 20px;
    margin-bottom: 45px;
    &:before {
      position: absolute;
      top: -50px;
      left: 50%;
      width: 1px;
      height: 45px;
      background: #000;
      content: "";
      @include mq(sp) {
        height: 20px;
        top: -27px;
      }
    }
    @include mq(sp) {
      margin-bottom: 25px;
      font-size: 19px;
    }
    &-inner {
      position: relative;
      display: inline-block;
      &:before {
        position: absolute;
        top: -10px;
        left: -80px;
        width: 12px;
        height: 90px;
        background-image: url("../img/common/ico_ttl_left_02.png");
        background-repeat: no-repeat;
        background-position: left top;
        background-size: auto 100%;
        content: "";
        @include mq(sp) {
          top: 0;
          height: 55px;
          left: -35px;
        }
      }
      &:after {
        position: absolute;
        top: -10px;
        right: -80px;
        width: 12px;
        height: 90px;
        background-image: url("../img/common/ico_ttl_right_02.png");
        background-repeat: no-repeat;
        background-position: left top;
        background-size: auto 100%;
        content: "";
        @include mq(sp) {
          top: 0;
          height: 55px;
          right: -35px;
        }
      }
    }
    &-en {
      font-size: 10px;
      display: block;
      @include mq(sp) {
        font-size: 8px;
      }
    }
  }
  &__line-ttl {
    position: relative;
    font-size: 20px;
    text-align: center;
    margin-bottom: 30px;
    padding-bottom: 25px;
    &:before {
      position: absolute;
      bottom: 0;
      left: 50%;
      width: 40px;
      margin-left: -20px;
      height: 3px;
      background-image: url("../img/common/line_02.png");
      background-repeat: no-repeat;
      background-position: left center;
      background-size: auto 3px;
      content: "";
    }
  }
  &__sub-ttl {
    font-size: 20px;
    margin-bottom: 20px;
    @include mq(sp) {
      font-size: 18px;
    }
    &--fig {
      width: 730px;
      @include mq(sp) {
        width: 100%;
      }
    }
  }
  .half-list__item {
    .page-section__sub-ttl {
      padding-top: 20px;
      @include mq(sp) {
        padding-top: 0;
      }
    }
  }
  &__txt {
    margin-bottom: 45px;
     @include mq(sp) {
      margin-bottom: 20px;
    }
    &--center {
      margin-bottom: 45px;
      text-align: center;
    }
    &--fig {
      width: 730px;
      @include mq(sp) {
        width: 100%;
      }
    }
  }
  &__txt-wrap {
    margin-bottom: 80px;
    float: left;
    @include mq(sp) {
      float: none;
      margin-bottom: 40px;
    }
  }
  &__fig {
    float: right;
    width: 308px;
    @include mq(sp) {
      margin-bottom: 70px;
      float: none;
      width: 100%;
    }
  }
  &__img-wrap {
    position: relative;
    z-index: 2;
    margin-bottom: 45px;
    &:before {
      right: -10px;
      bottom: -10px;
      @include bg-dot;
      border-radius: 10px;
      @include mq(sp) {
        border-radius: 8px;
      }
    }
    &--dot02 {
      &:before {
        @include bg-dot-02;
        border-radius: 10px;
        @include mq(sp) {
          border-radius: 8px;
        }
      }
    }
  }
  &__img {
    position: relative;
    z-index: 3;
    border: 1px solid #000;
    border-radius: 10px;
    overflow: hidden;
    @include mq(sp) {
      border-radius: 8px;
    }
  }
  &--line {
    margin-top: 50px;
    .container {
      padding-top: 160px;
      position: relative;
      @include mq(sp) {
        padding-top: 70px;
      }
      &:before {
        position: absolute;
        top: 0;
        left: -60px;
        width: 1200px;
        height: 1px;
        background: #000;
        content: "";
      }
    }
  }
  &--menu {
    .container {
      padding-top: 160px;
      position: relative;
      @include mq(sp) {
        padding-top: 70px;
      }
      &:before {
        position: absolute;
        top: 0;
        left: -60px;
        width: 1200px;
        height: 4px;
        background-image: url("../img/common/line_02.png");
        background-size: auto 4px;
        content: "";
      }
      &:after {
        display: block;
        width: 210px;
        background: #fff;
        position: absolute;
        top: -8px;
        left: 50%;
        margin-left: -105px;
        font-size: 12px;
        @extend .font-en;
        content: "RELAXATION MENU";
        text-align: center;
        @include mq(sp) {
          font-size: 8px;
          width: 170px;
          margin-left: -85px;
        }
      }
    }
  }
  .page-top {
    &:before {
    top: -80px;
    left: inherit;
    right: 0;
    }
  }
  &--rent {
    .page-top__txt-wrap {
      padding-top: 0;
    }
    .page-top__img-wrap {
      margin-top: 80px;
      @include mq(sp) {
        margin-top: 0;
      }
    }
  }
}
.calendar {
  margin-bottom: 130px;
  @include mq(sp) {
    margin-bottom: 60px;
  }
  &__ttl {
    position: relative;
    font-size: 18px;
    margin-bottom: 20px;
    &:before {
      position: absolute;
      top: 50%;
      left: -40px;
      width: 30px;
      height: 1px;
      background: #000;
      content: "";
      @include mq(sp) {
        display: none;
      }
    }
  }
  &__wrap {
    margin-bottom: 80px;
    iframe {
      width: 100%;
      height: 600px;
      @include mq(sp) {
        height: 500px;
      }
    }
  }
  &__btn {
    position: relative;
    display: block;
    text-align: center;
    line-height: 80px;
    height: 80px;
    width: 460px;
    background: #feecd2;
    border: 1px solid #000;
    border-radius: 6px;
    margin: 0 auto;
    @include mq(sp) {
      width: 100%;
      max-width: 271px;
      line-height: 54px;
      height: 54px;
    }
    &:before {
      position: absolute;
      top: 50%;
      left: -23px;
      width: 45px;
      height: 1px;
      background: #000;
      content: "";
      transition: all 300ms;
    }
    &:hover {
      text-decoration: none;
      &:before {
        left: -13px;
      }
    }
  }
}
@import 'blog';
@import 'form';